export const ORDER_TYPE_OPTIONS = {
  "Retail-Store": [
    "D00",
    "D01",
    "D03",
    "D04",
    "D05",
    "D06",
    "D07",
    "D08",
    "D10",
  ].map((item) => {
    return {
      label: item,
      value: item,
    };
  }),
  "Outlet-Store": [
    "D00",
    "D01",
    "D03",
    "D04",
    "D05",
    "D06",
    "D07",
    "D08",
    "D10",
  ].map((item) => {
    return {
      label: item,
      value: item,
    };
  }),
  "Ecom-Store": [
    "D00",
    "D01",
    "D03",
    "D04",
    "D05",
    "D06",
    "D07",
    "D08",
    "D10",
  ].map((item) => {
    return {
      label: item,
      value: item,
    };
  }),
};
