import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";

const ReactTollTip = React.memo(({ ...instance }) => {

  const { row, value, column,id,sliceLength } = instance
  const [string, setString] = useState("")

  useEffect(() => {
    if (Array.isArray(value)) {
      setString(value[0] === "*" ? value.slice(1).join(", ") : value.join(", "))
    }
    else {
      setString(value)
    }
  }, [value])
  return (
    <>
    {
      !sliceLength || sliceLength < string?.length ?
      <>
      <div data-tip data-for={ id ? id : row.id + column.id } className="text__ellipsis">
        {sliceLength ? string.slice(0, sliceLength) : string}
      </div>
      <ReactTooltip id={ id ? id : row.id + column.id } className="react__tool__tip__custom__class" place="bottom" effect="float">
        {string}
      </ReactTooltip>
      </>
      : string
    }
    </>
  );
});

export default ReactTollTip;
