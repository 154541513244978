import { handleActions, combineActions } from "redux-actions";
import immutable from "immutability-helper";
import { styleIndex } from "../Constraints/Constraints"
import { cloneDeep } from "lodash";  
import { FETCH_FILTER_SUCCESS,
         FETCH_FILTER_DATA,
         FETCH_FILTER_ERROR,
         RESET_FILTERS_DATA, 
         FETCH_STORE_GROUP_DATA_SUCCESS, 
         FETCH_STORE_GROUP_DATA,
         FETCH_STORE_GRADE_DATA_SUCCESS, 
         FETCH_STORE_GRADE_DATA,
         FETCH_STORE_DATA_SUCCESS, 
         FETCH_STORE_DATA,
         FETCH_CONSTRAINTS_POPUP_ERROR,
         FETCH_CONSTRAINTS_POPUP_SUCCESS,
         FETCH_CONSTRAINTS_POPUP,
         UPDATE_TABLEDATA_ERROR,
         UPDATE_TABLEDATA,
         UPDATE_TABLEDATA_SUCCESS,
         FETCH_STORE_GROUP_DATA_ERROR,
         FETCH_STORE_GRADE_DATA_ERROR,
         FETCH_STORE_DATA_ERROR,
         RESET_ALL,
         FETCH_MIN_PER_STORE,
         FETCH_MIN_PER_STORE_SUCCESS,
         FETCH_MIN_PER_STORE_ERROR,
         SET_PREV_STOREGROUP_DATA,
         RESET_STYLE_INDEX,
         RESET_UPDATE_TABLEDATA,
         FETCH_DEFAULT_CONS_SUCCESS,
         FETCH_DEFAULT_CONS_DATA,
         FETCH_DEFAULT_CONS_DATA_ERROR,
         FETCH_DEFAULT_CONS_DATA_SUCCESS,
         GET_CHECK_ALL_DATA_SUCCESS,
         UPDATE_DEFAULT_DATA,
         UPDATE_DEFAULT_DATA_SUCCESS,
         UPDATE_DEFAULT_DATA_ERROR,
         GENERATE_EXCEL,
         GENERATE_EXCEL_SUCCESS,
         GENERATE_EXCEL_ERROR,
         RESET_DOWNLOAD_EXCEL,
         } from "./ConstraintsAction";

const RESET_FETCH_FILTERS_DATA = combineActions(RESET_FILTERS_DATA,FETCH_FILTER_DATA)

const getPageIndex = (p_row) => {
  let sum = p_row?.length
  p_row.forEach(val => {
    sum += val.subRows.length
  })
  return sum;
}

export const constraintsState = {
  storeGroupData: [],
  storeGradeData: [],
  storeData: [],
  storeGroupError: false,
  storeGradeError: false,
  storeError: false,
  popUpError: false,
  storeGrouploading: false,
  storeGradeloading: false,
  storeloading: false,
  departmentOptions: [],
  totalMinimum: null,
  genderOptions: [],
  rbuOptions: [],
  dcsOptions: [],
  level5Options: [],
  level6Options: [],
  level7Options: [],
  styleOptions: [],
  colourOptions: [],
  sizeOptions: [],
  storeGradeOptions: [],
  popUpData: [],
  popUpLoader: false,
  isUpdated: false,
  error: false,
  filterlabels : {},
  storeTotalCount: null,
  storeGroupTotalCount: null,
  storeGradeTotalCount: null,
  storeIndex: 0,
  storeGroupIndex: 0,
  storeGradeIndex: 0,
  storeRequest: [],
  callNext: false,
  styleIndex: 0,
  out_of_data: false,
  limit: 100,
  pageIndex: 0,
  batchIdReqMapping: {},
  countryOptions: [],
  assortIndOptions: [],
  lmsStoreAttributeOptions: [],
  lmsStoreValueOptions: [],
  level3Options: [],
  defaultStoreData: [],
  nextIndex: 0,
  totalCount: 0,
  excelData: [],
  downloadNextIndex: 0,
  downloadTotalCount: 0,
  downloadExcelError: null,
  downloadLoading: false,
  storeGradeOptions: [],
  productTagOptions: [],
  defaultConstDepartmentOptions: [],
  level4OptionsDefaultCons: [],
  level5OptionsDefaultCons: [],
  level6OptionsDefaultCons: [],
};

export default {
constraints: handleActions(
{
  [FETCH_FILTER_SUCCESS]: (state, { payload }) => {
    switch (payload.key) {
      case "l1":
        return immutable(state, {
          departmentOptions: {
            $set:
              payload && payload.data.departmentOptions
                ? payload.data.departmentOptions
                : state.departmentOptions,
          },
          countryOptions: {
            $set:
              payload && payload.data.countryOptions
                ? payload.data.countryOptions
                : state.countryOptions,
          },
          genderOptions: [],
          rbuOptions: [],
          filterlabels : {
            $set: 
              payload && payload.data.filterMapping
                ? payload.data.filterMapping
                : state.filterMapping
          }
        });
        case "l10":
          return immutable(state, {
            genderOptions: {
              $set:
                payload && payload.data.genderOptions
                  ? payload.data.genderOptions
                  : state.genderOptions,
            },
            filterlabels : {
              $set: 
                payload && payload.data.filterMapping
                  ? payload.data.filterMapping
                  : state.filterMapping
            }
          });
          case "l11":
          return immutable(state, {
            rbuOptions: {
              $set:
                payload && payload.data.rbuOptions
                  ? payload.data.rbuOptions
                  : state.rbuOptions,
            },
            filterlabels : {
              $set: 
                payload && payload.data.filterMapping
                  ? payload.data.filterMapping
                  : state.filterMapping
            }
          });
        case "l2":
          return immutable(state, {
            genderOptions: {
              $set:
                payload && payload.data.genderOptions
                  ? payload.data.genderOptions
                  : state.genderOptions,
            },
            rbuOptions: {
              $set:
                payload && payload.data.rbuOptions
                  ? payload.data.rbuOptions
                  : state.rbuOptions,
            },
                dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },
              sizeOptions: {
                $set:
                  payload && payload.data.sizeOptions
                    ? payload.data.sizeOptions
                    : state.sizeOptions,
              },
              storeGradeOptions: {
                $set:
                  payload && payload.data.storeGradeOptions
                    ? payload.data.storeGradeOptions
                    : state.storeGradeOptions,
              },
            filterlabels : {
              $set: 
                payload && payload.data.filterMapping
                  ? payload.data.filterMapping
                  : state.filterMapping
            }
          });
          case "l3":
            return immutable(state, {
              rbuOptions: {
                $set:
                  payload && payload.data.rbuOptions
                    ? payload.data.rbuOptions
                    : state.rbuOptions,
              },
              dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },
              sizeOptions: {
                $set:
                  payload && payload.data.sizeOptions
                    ? payload.data.sizeOptions
                    : state.sizeOptions,
              },
              storeGradeOptions: {
                $set:
                  payload && payload.data.storeGradeOptions
                    ? payload.data.storeGradeOptions
                    : state.storeGradeOptions,
              },
              assortIndOptions: {
                $set:
                  payload && payload.data.assortIndOptions
                    ? payload.data.assortIndOptions
                    : state.assortIndOptions,
              },
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l4":
          return immutable(state, {
             dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },
              sizeOptions: {
                $set:
                  payload && payload.data.sizeOptions
                    ? payload.data.sizeOptions
                    : state.sizeOptions,
              },
              storeGradeOptions: {
                $set:
                  payload && payload.data.storeGradeOptions
                    ? payload.data.storeGradeOptions
                    : state.storeGradeOptions,
              },
              assortIndOptions: {
                $set:
                  payload && payload.data.assortIndOptions
                    ? payload.data.assortIndOptions
                    : state.assortIndOptions,
              },
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
          });
          case "l9":
          return immutable(state, {
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },
              sizeOptions: {
                $set:
                  payload && payload.data.sizeOptions
                    ? payload.data.sizeOptions
                    : state.sizeOptions,
              },
              storeGradeOptions: {
                $set:
                  payload && payload.data.storeGradeOptions
                    ? payload.data.storeGradeOptions
                    : state.storeGradeOptions,
              },
              assortIndOptions: {
                $set:
                  payload && payload.data.assortIndOptions
                    ? payload.data.assortIndOptions
                    : state.assortIndOptions,
              },
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
          });
          case "l7":
            return immutable(state, {
                level6Options: {
                  $set:
                    payload && payload.data.level6Options
                      ? payload.data.level6Options
                      : state.level6Options,
                },
                level7Options: {
                  $set:
                    payload && payload.data.level7Options
                      ? payload.data.level7Options
                      : state.level7Options,
                },
                styleOptions: {
                  $set:
                    payload && payload.data.styleOptions
                      ? payload.data.styleOptions
                      : state.styleOptions,
                },
                colourOptions: {
                  $set:
                    payload && payload.data.colourOptions
                      ? payload.data.colourOptions
                      : state.colourOptions,
                },
                sizeOptions: {
                  $set:
                    payload && payload.data.sizeOptions
                      ? payload.data.sizeOptions
                      : state.sizeOptions,
                },
                storeGradeOptions: {
                  $set:
                    payload && payload.data.storeGradeOptions
                      ? payload.data.storeGradeOptions
                      : state.storeGradeOptions,
                },
                assortIndOptions: {
                  $set:
                    payload && payload.data.assortIndOptions
                      ? payload.data.assortIndOptions
                      : state.assortIndOptions,
                },
                filterlabels : {
                  $set: 
                    payload && payload.data.filterMapping
                      ? payload.data.filterMapping
                      : state.filterMapping
                }
            });
            case "l8":
              return immutable(state, {
                  level7Options: {
                    $set:
                      payload && payload.data.level7Options
                        ? payload.data.level7Options
                        : state.level7Options,
                  },
                  styleOptions: {
                    $set:
                      payload && payload.data.styleOptions
                        ? payload.data.styleOptions
                        : state.styleOptions,
                  },
                  colourOptions: {
                    $set:
                      payload && payload.data.colourOptions
                        ? payload.data.colourOptions
                        : state.colourOptions,
                  },
                  sizeOptions: {
                    $set:
                      payload && payload.data.sizeOptions
                        ? payload.data.sizeOptions
                        : state.sizeOptions,
                  },
                  storeGradeOptions: {
                    $set:
                      payload && payload.data.storeGradeOptions
                        ? payload.data.storeGradeOptions
                        : state.storeGradeOptions,
                  },
                  assortIndOptions: {
                    $set:
                      payload && payload.data.assortIndOptions
                        ? payload.data.assortIndOptions
                        : state.assortIndOptions,
                  },
                  filterlabels : {
                    $set: 
                      payload && payload.data.filterMapping
                        ? payload.data.filterMapping
                        : state.filterMapping
                  }
              });  
          case "l5":
            return immutable(state, {
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },
              sizeOptions: {
                $set:
                  payload && payload.data.sizeOptions
                    ? payload.data.sizeOptions
                    : state.sizeOptions,
              },
              storeGradeOptions: {
                $set:
                  payload && payload.data.storeGradeOptions
                    ? payload.data.storeGradeOptions
                    : state.storeGradeOptions,
              },
              assortIndOptions: {
                $set:
                  payload && payload.data.assortIndOptions
                    ? payload.data.assortIndOptions
                    : state.assortIndOptions,
              },
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          default:
          return immutable(state, {
            filterlabels : {
              $set: 
                payload && payload.data.filterMapping
                  ? payload.data.filterMapping
                  : state.filterMapping
            }
          });
    };
  },
 [RESET_ALL]: (state, {payload }) => {
  return immutable(state, {
    storeGroupData: { $set:  []},
    storeGradeData: { $set:  []},
    storeData: { $set:  []},
    storeGroupError: { $set:  false},
    storeGradeError: { $set:  false},
    storeError: { $set:  false},
    popUpError: { $set:  false},
    storeGrouploading: { $set:  false},
    storeGradeloading: { $set:  false},
    storeloading: { $set:  false},
    departmentOptions: { $set:  []},
    genderOptions: { $set:  []},
    rbuOptions: { $set:  []},
    dcsOptions: { $set:  []},
    level5Options: { $set:  []},
    level6Options: { $set:  []},
    level7Options: { $set:  []},
    styleOptions: { $set:  []},
    colourOptions: { $set:  []},
    sizeOptions: { $set:  []},
    storeGradeOptions: { $set:  []},
    popUpData: { $set:  []},
    popUpLoader: { $set:  false},
    isUpdated: { $set:  false},
    error: { $set:  false},
    totalMinimum: { $set: null},
    storeTotalCount: { $set: null},
    storeGroupTotalCount: { $set: null},
    storeGradeTotalCount: { $set: null},
    storeGroupIndex: { $set: 0},
    storeGradeIndex: { $set: 0},
    storeRequest: { $set: []},
    storeIndex: { $set: 0},
    callNext: { $set: false},
    out_of_data: { $set: false},
    styleIndex: { $set: 0},
    pageIndex: { $set: 0},
    assortIndOptions: {$set: []},
    defaultStoreData: {$set: []},
    totalCount:{ $set: 0},
    nextIndex: { $set: 0},
    level3Options: {$set: []},
    storeOptions: {$set: []},
    defaultConstDepartmentOptions: {$set: []},
  });
 },
 [RESET_FILTERS_DATA]: (state, {payload }) => {
   return immutable(state, {
    storeTotalCount: { $set: null},
    storeGroupTotalCount: { $set: null},
    storeGradeTotalCount: { $set: null},
    storeGroupIndex: { $set: 0},
    storeGradeIndex: { $set: 0},
    storeRequest: { $set: []},
    storeIndex: { $set: 0},
    storeData: { $set: [] },
    storeGradeData: { $set: [] },
    storeGroupData: { $set: [] },
    genderOptions: { $set: [] },
    rbuOptions : { $set: []},
    dcsOptions: { $set:  []},
    level5Options: { $set:  []},
    level6Options: { $set:  []},
    level7Options: { $set:  []},
    styleOptions : { $set: []},
    colourOptions : { $set: []},
    sizeOptions : { $set: []},
    storeGradeOptions: { $set: []},
    totalMinimum: { $set: null},
    error: { $set:  false},
    callNext: { $set: false},
    out_of_data: { $set: false},
    styleIndex: { $set: 0},
    pageIndex: { $set: 0},
    assortIndOptions: {$set: []},
    level4OptionsDefaultCons: {$set: []},
    level5OptionsDefaultCons: {$set: []},
    level6OptionsDefaultCons: {$set: []},
   });
 },
 [RESET_STYLE_INDEX]: (state, {payload }) => {
  return immutable(state, {
    callNext: { $set: false},
    out_of_data: { $set: false},
    styleIndex: { $set: 0},
    pageIndex: { $set: 0},
  });
},
  [FETCH_STORE_GROUP_DATA]: (state, {payload }) => {
    payload.pageIndex = state.pageIndex
    return immutable(state, {
      error: { $set:  false},
    out_of_data : { $set:  false }, 
    storeGrouploading: { $set : true },
    storeGroupError: { $set: false }
    });
  },
  [FETCH_STORE_GROUP_DATA_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
    storeGroupData: { $set: (payload && payload.data) || [] },
    callNext : { $set: (state.storeGroupData.length && !payload?.data?.length) ? true : false },
    // styleIndex : { $set: (state.storeGroupData.length && !payload?.data?.length) ? state.styleIndex + styleIndex : state?.styleIndex },
    out_of_data : { $set: (payload && payload.out_of_data) || false }, 
    limit: { $set: (payload && payload.limit) || 100},
    styleIndex : { $set: (payload && payload.styleIndex) || 0 },
    // pageIndex : { $set: (state.storeGroupData.length && !payload?.data?.length) ? state.pageIndex  : state.pageIndex + getPageIndex(payload?.data)},
    pageIndex : { $set: (payload &&  state.pageIndex + getPageIndex(payload?.data)) || 0 },
    batchIdReqMapping : { $set : (payload && {...state?.batchIdReqMapping ,...payload?.mapping}) || {}},
    storeGroupTotalCount: { $set: (payload && payload.count) || 0 },
    storeGroupIndex: { $set: (payload && payload.index) || 0 },
    storeGrouploading: { $set : false },
    storeGroupError: { $set: false }
    });
  },
  [FETCH_STORE_GROUP_DATA_ERROR]: (state, {payload }) => {
    return immutable(state, {
    storeGroupData: { $set: [] },
    storeGrouploading: { $set : false },
    storeGroupError: { $set: true },
    out_of_data : { $set:  false }, 
    });
  },
  [FETCH_MIN_PER_STORE_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
    totalMinimum: { $set: (payload && payload.data) || [] },
    });
  },
  [FETCH_MIN_PER_STORE_ERROR]: (state, {payload }) => {
    return immutable(state, {
      totalMinimum: { $set: ('N/A')}
    });
  },
  [FETCH_STORE_GRADE_DATA]: (state, {payload }) => {
    return immutable(state, {
    error: { $set:  false},
    out_of_data : { $set:  false }, 
    storeGradeloading: { $set: true},
    storeGradeError: { $set: false }
    });
  },
  [FETCH_STORE_GRADE_DATA_ERROR]: (state, {payload }) => {
    return immutable(state, {
    storeGradeData: { $set: [] },
    storeGradeloading: { $set : false },
    out_of_data : { $set:  false }, 
    storeGradeError: { $set: true }
    });
  },
  [FETCH_STORE_GRADE_DATA_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
    storeGradeData: { $set: (payload && payload.data) || [] },
    callNext : { $set: (state.storeGradeData.length && !payload?.data?.length) ? true : false },
    out_of_data : { $set: (payload && payload.out_of_data) || false }, 
    limit: { $set: (payload && payload.limit) || 100},
    // styleIndex : { $set: (state.storeGradeData.length && !payload?.data?.length) ? state.styleIndex + styleIndex : state?.styleIndex },
    styleIndex : { $set: (payload && payload.styleIndex) || 0 },
    // pageIndex : { $set: (state.storeGradeData.length && !payload?.data?.length) ? state.pageIndex  : state.pageIndex + getPageIndex(payload?.data)},
    pageIndex : { $set: (payload &&  state.pageIndex + getPageIndex(payload?.data)) || 0 },
    storeGradeTotalCount: { $set: (payload && payload.count) || 0 },
    storeGradeIndex:  { $set: (payload && payload.index) || 0 },
    storeGradeloading: { $set : false },
    storeGradeError: { $set: false }
    });
  },
  [FETCH_STORE_DATA]: (state, {payload }) => {
    return immutable(state, {
      error: { $set:  false},
      out_of_data : { $set:  false }, 
      storeError: { $set: false },
      storeloading: { $set: true},
      storeRequest: { $set: []}
    });
  },
  [FETCH_STORE_DATA_ERROR]: (state, {payload }) => {
    return immutable(state, {
      storeData: { $set: [] },
      storeloading: { $set : false },
      out_of_data : { $set:  false }, 
      storeError: { $set: true },
      storeRequest: { $set: []}
    });
  },
  [FETCH_STORE_DATA_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
    storeData: { $set: (payload && payload.data) || [] },
    callNext : { $set: (state.storeData.length && !payload?.data?.length) ? true : false },
    out_of_data : { $set: (payload && payload.out_of_data) || false }, 
    limit: { $set: (payload && payload.limit) || 100},
    // styleIndex : { $set: (state.storeData.length && !payload?.data?.length) ? state.styleIndex + styleIndex : state?.styleIndex },
    styleIndex : { $set: (payload && payload.styleIndex) || 0 },
    // pageIndex : { $set: (state.storeData.length && !payload?.data?.length) ? state.pageIndex  : state.pageIndex + payload?.data?.length},
    pageIndex : { $set: (payload &&  state.pageIndex + payload?.data?.length) || 0 },
    storeTotalCount: { $set: (payload && payload.count) || 0 },
    storeIndex: { $set: (payload && payload.index) || 0 },
    storeloading: { $set : false },
    storeError: { $set : false },
    storeRequest: { $set :  (payload && payload.req) || []}
    });
  },
  [FETCH_CONSTRAINTS_POPUP]: (state, {payload}) => {
    return immutable(state, {
      popUpLoader: { $set: true },
      popUpError: { $set : false }
    })
  },
  [FETCH_CONSTRAINTS_POPUP_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
    popUpLoader: { $set: false},
    popUpData: { $set: (payload && payload.data ) || [] },
    });
  },
  [FETCH_CONSTRAINTS_POPUP_ERROR]: (state, {payload }) => {
    return immutable(state, {
    popUpLoader: { $set: false},
    popUpData: { $set: [] },
    popUpError: { $set: true}
    });
  },
  [UPDATE_TABLEDATA]: (state, {payload }) => {
    return immutable(state, {
      isUpdated: { $set: false},
      error: { $set:  false},
    })
  },
  [UPDATE_TABLEDATA_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
      error: { $set:  false},
      isUpdated: { $set: true},
    })
  },
  [UPDATE_TABLEDATA_ERROR]: (state, {payload}) => {
    return immutable(state, {
      storeGrouploading: { $set:  false},
      storeGradeloading: { $set:  false},
      storeloading: { $set:  false},
      isUpdated: { $set: true},
      error: { $set:  true},
    })
  },
  [RESET_UPDATE_TABLEDATA]: (state, {payload }) => {
    return immutable(state, {
      isUpdated: { $set: false},
    })
  },

  [FETCH_DEFAULT_CONS_SUCCESS]: (state, { payload }) => {
    switch (payload.key) {
      case "l1":
        return immutable(state, {
          channelOptions: {
            $set:
              payload && payload.data.channelOptions
                ? payload.data.channelOptions
                : state.channelOptions,
          },
          defaultConstDepartmentOptions: [],
          level3Options: [],
          filterlabels : {
            $set: 
              payload && payload.data.filterMapping
                ? payload.data.filterMapping
                : state.filterMapping
          },
        });
        case "l10":
          return immutable(state, {
            defaultConstDepartmentOptions: {
              $set:
                payload && payload.data.defaultConstDepartmentOptions
                  ? payload.data.defaultConstDepartmentOptions
                  : state.defaultConstDepartmentOptions,
            },
            filterlabels : {
              $set: 
                payload && payload.data.filterMapping
                  ? payload.data.filterMapping
                  : state.filterMapping
            }
          });
          case "l11":
          return immutable(state, {
            level3Options: {
              $set:
                payload && payload.data.level3Options
                  ? payload.data.level3Options
                  : state.level3Options,
            },
            filterlabels : {
              $set: 
                payload && payload.data.filterMapping
                  ? payload.data.filterMapping
                  : state.filterMapping
            }
          });
        case "l2":
          return immutable(state, {
            defaultConstDepartmentOptions: {
              $set:
                payload && payload.data.defaultConstDepartmentOptions
                  ? payload.data.defaultConstDepartmentOptions
                  : state.defaultConstDepartmentOptions,
            },
            level3Options: {
              $set:
                payload && payload.data.level3Options
                  ? payload.data.level3Options
                  : state.level3Options,
            },
            sizeOptions: {
              $set:
                payload && payload.data.sizeOptions
                  ? payload.data.sizeOptions
                  : state.sizeOptions,
            },
            storeOptions: {
              $set:
                payload && payload.data.storeOptions
                  ? payload.data.storeOptions
                  : state.storeOptions,
            },
            assortIndOptions: {
              $set:
                payload && payload.data.assortIndOptions
                  ? payload.data.assortIndOptions
                  : state.assortIndOptions,
            },
            productTagOptions: {
              $set:
                payload && payload.data.productTagOptions
                  ? payload.data.productTagOptions
                  : state.productTagOptions,
            },            
            filterlabels : {
              $set: 
                payload && payload.data.filterMapping
                  ? payload.data.filterMapping
                  : state.filterMapping
            },
          });
          case "l3":
            return immutable(state, {
              level3Options: {
                $set:
                  payload && payload.data.level3Options
                    ? payload.data.level3Options
                    : state.level3Options,
              },
                    sizeOptions: {
                      $set:
                        payload && payload.data.sizeOptions
                          ? payload.data.sizeOptions
                          : state.sizeOptions,
                    },
                    storeOptions: {
                      $set:
                        payload && payload.data.storeOptions
                          ? payload.data.storeOptions
                          : state.storeOptions,
                    },
                    productTagOptions: {
                      $set:
                        payload && payload.data.productTagOptions
                          ? payload.data.productTagOptions
                          : state.productTagOptions,
                    },
                    assortIndOptions: {
                      $set:
                        payload && payload.data.assortIndOptions
                          ? payload.data.assortIndOptions
                          : state.assortIndOptions,
              },  
              level4OptionsDefaultCons: {
                $set:
                  payload && payload.data.level4Options
                    ? payload.data.level4Options
                    : state.level4Options,
              },
              level5OptionsDefaultCons: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6OptionsDefaultCons: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },            
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
            case "l4":
            return immutable(state, {
              sizeOptions: {
                $set:
                  payload && payload.data.sizeOptions
                    ? payload.data.sizeOptions
                    : state.sizeOptions,
              },
              storeOptions: {
                $set:
                  payload && payload.data.storeOptions
                    ? payload.data.storeOptions
                    : state.storeOptions,
              },
              productTagOptions: {
                $set:
                  payload && payload.data.productTagOptions
                    ? payload.data.productTagOptions
                    : state.productTagOptions,
              },
              assortIndOptions: {
                $set:
                  payload && payload.data.assortIndOptions
                    ? payload.data.assortIndOptions
                    : state.assortIndOptions,
              },
              level4OptionsDefaultCons: {
                $set:
                  payload && payload.data.level4Options
                    ? payload.data.level4Options
                    : state.level4Options,
              },
              level5OptionsDefaultCons: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6OptionsDefaultCons: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            }); 
          case "l9":
            return immutable(state, {
              storeOptions: {
                $set:
                  payload && payload.data.storeOptions
                    ? payload.data.storeOptions
                    : state.storeOptions,
              },
              productTagOptions: {
                $set:
                  payload && payload.data.productTagOptions
                    ? payload.data.productTagOptions
                    : state.productTagOptions,
              },
              level5OptionsDefaultCons: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6OptionsDefaultCons: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            }); 
          case "l7":
            return immutable(state, {
              storeOptions: {
                $set:
                  payload && payload.data.storeOptions
                    ? payload.data.storeOptions
                    : state.storeOptions,
              },
              productTagOptions: {
                $set:
                  payload && payload.data.productTagOptions
                    ? payload.data.productTagOptions
                    : state.productTagOptions,
              },
              level6OptionsDefaultCons: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l8":
              return immutable(state, {
                storeOptions: {
                  $set:
                    payload && payload.data.storeOptions
                      ? payload.data.storeOptions
                      : state.storeOptions,
                },
                productTagOptions: {
                  $set:
                    payload && payload.data.productTagOptions
                      ? payload.data.productTagOptions
                      : state.productTagOptions,
                },
                filterlabels : {
                  $set: 
                    payload && payload.data.filterMapping
                      ? payload.data.filterMapping
                      : state.filterMapping
                }
              });          
          default:
          return immutable(state, {
            filterlabels : {
              $set: 
                payload && payload.data.filterMapping
                  ? payload.data.filterMapping
                  : state.filterMapping
            }
          });
    };
  },
  [FETCH_DEFAULT_CONS_DATA]: (state, {payload }) => {
    return immutable(state, {
      error: { $set:  false},
      out_of_data : { $set:  false }, 
      storeError: { $set: false },
      storeloading: { $set: true},
      storeRequest: { $set: []}
    });
  },
  
  [FETCH_DEFAULT_CONS_DATA_ERROR]: (state, {payload }) => {
    return immutable(state, {
      defaultStoreData: { $set: [] },
      storeloading: { $set : false },
      out_of_data : { $set:  false }, 
      storeError: { $set: true },
      storeRequest: { $set: []}
    });
  },
  [FETCH_DEFAULT_CONS_DATA_SUCCESS]: (state, payload) => {
    return immutable(state, {
      defaultStoreData: { $set: (payload && payload.data) || [] },
      totalCount: { $set: (payload && payload.totalCount) || 0 },
      nextIndex: { $set: (payload && payload.nextIndex) || 0 },
      storeloading: { $set : false },
      storeError: { $set : false },
      storeRequest: { $set :  (payload && payload.req) || []},
      storeGradeOptions : { $set: (payload && payload.storeGradeOptions) || [] },
    });
  },

  [GET_CHECK_ALL_DATA_SUCCESS]: (state, payload) => {
    return immutable(state, {
      checkAllData: { $set: payload && payload.data || [] },
      checkAllNextIndex: { $set: payload && payload.nextIndex || 0 },
      checkAllTotalCount: { $set: payload && payload.totalCount || 0 },
    });
  },

  [UPDATE_DEFAULT_DATA]: (state, {payload }) => {
    return immutable(state, {
      isUpdated: { $set: false},
      error: { $set:  false},
    })
  },
  [UPDATE_DEFAULT_DATA_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
      error: { $set:  false},
      isUpdated: { $set: true},
    })
  },
  [UPDATE_DEFAULT_DATA_ERROR]: (state, {payload}) => {
    return immutable(state, {
      storeGrouploading: { $set:  false},
      storeGradeloading: { $set:  false},
      storeloading: { $set:  false},
      isUpdated: { $set: true},
      error: { $set:  true},
    })
  },
  [GENERATE_EXCEL]: (state, { payload }) => {
    return immutable(state, {
        excelLoading: { $set: true },
        // tableDataLoading: { $set: true },
        excelData: { $set: [] },
        downloadExcelError: { $set: null },
        downloadLoading: { $set: true }
    });
},
[GENERATE_EXCEL_SUCCESS]: (state, payload) => {
    return immutable(state, {
        // tableDataLoading: { $set: false },
        excelData: { $set: (payload && payload.excelData) || [] },
        tableDataSuccess: { $set: true },
        downloadNextIndex: { $set: (payload && state.downloadLoading && payload.nextIndex) || 0 },
        downloadTotalCount: { $set: (payload && state.downloadLoading && payload.totalCount) || 0 },
        downloadExcelError: { $set: null }
    });
},
[GENERATE_EXCEL_ERROR]: (state, payload) => {
    return immutable(state, {
        excelData: { $set: [] },
        downloadNextIndex: { $set: 0 },
        downloadTotalCount: { $set: 0 },
        downloadExcelError: { $set: payload.error }
    });
},
[RESET_DOWNLOAD_EXCEL]: (state, { payload }) => {
  return immutable(state, {
    excelData: { $set: [] },
    downloadNextIndex: { $set: 0 },
    downloadTotalCount: { $set: 0 },
    downloadExcelError: { $set: null },
    downloadLoading: { $set: false },
  });
},
},

constraintsState
),
};


