import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _, { cloneDeep, isEmpty } from "lodash";
import PageLoader from "../../components/Loader/PageLoader";
import ReactTable from "../../components/Table/ReactTable";
import WithDynamicPlugins from "../../components/Table/WithPlugins";
import {
  levelFiltersLabelFormatter,
} from "../../utils/filterLevelMapping";
import SetAllModal from "../../components/ErrorModal/SetAllModal";
import * as Notify from "../../components/Notification/Notifications";
import NumberRangeColumnFilterServerSide from "../../components/Filters/NumberRangeColumnFilterServerSide";
import ColumnFilterServerSide from "../../components/Filters/ColumnFilterServerSide";
import InputCell from "../../components/Table/Cellrenderer/InputCell";
import {
  fetchDefaultConstraintsData,
  generateExcel,
  resetAll,
  resetDownloadExcel,
  resetStyleIndex,
  updateDefaultData,
} from "./ConstraintsAction";
import DefaultConstraintsFilter from "./DefaultConstraintsFilter";
import { removeCheckedArticles } from "../../utils/paginationHelper";
import DownloadProgressBar from "../../components/DownloadProgressBar/DownloadProgressBar";
import ExportFile from "../../components/ExportFile/ExportFile";
import { DEFAULT_CONSTRAINTS_COLUMNS_FORMATTING } from "../../components/ExportFile/ExportExcelConstants";
import SelectCell from "../../components/Table/Cellrenderer/SelectCell";

export const rowsCount = 1000;
export const styleIndex = 100;
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);
export function range(start, end) {
  return Array(end - start)
    .fill()
    .map((_, idx) => start + idx);
}

const DefaultConstraints = (props) => {

  const { downloadExcelData, downloadNextIndex, downloadExcelError, downloadTotalCount, resetDownloadExcel } = props

  const [RTinstance, setRTinstance] = useState(null);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [filters, setFilters] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [shouldLoading, setShouldLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState(null);
  const [prevIndex, setPrevIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(null);
  const [nextIndex, setIndex] = useState(0);
  // const [isDataLoading, setIsDataLoading] = useState(false)
  const [initialIndex, setInitialIndex] = useState(0);
  const [showSetAll, setShowSetAll] = useState(false);
  const [minPerStoreSA, setMinPerStoreSA] = useState(null);
  const [maxPerStoreSA, setMaxPerStoreSA] = useState(null);
  const [wosSA, setwosSA] = useState(null);
  const [storeGradeSA, setStoreGradeSA] = useState(null);
  const [storeData, setStoreData] = useState([]);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [initialSelectedRows, setInitialSelectedRows] = useState({});
  const [setAll, setSetAll] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(
    "*Avg. Min per store will be set to 0"
  );
  const [searchTermReq, setSearchTermReq] = useState({});
  const [sortReq, setSortReq] = useState({});
  const [isFirstCall, setIsFirstCall] = useState(true);
  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const [prevAction, setPrevAction] = useState("")
  const [checkedRows, setCheckedRows] = useState([])
  const [checkAll, setCheckAll] = useState([])
  const [downloadExcel, setDownloadExcel] = useState(false)
  const [nextIndexExcel, setNextIndexExcel] = useState(0)
  const [totalCountExcel, setTotalCountExcel] = useState(0)
  const [excelData, setExcelData] = useState([])
  const [downloadExcelLoading, setDownloadLoading] = useState(false)
  const [storeGradeOptions, setStoreGradeOptions] = useState(props.storeGradeOptions)

  const columns = React.useMemo(
    () => [
      {
        Header: levelFiltersLabelFormatter("level1"),
        accessor: "l1_name",
        disableFilters: true,
      },
      {
        Header:
          props?.filterLabels.level2 || levelFiltersLabelFormatter("level2"),
        accessor: "l2_name",
        disableFilters: true,
      },
      {
        Header:
          props?.filterLabels.level3 || levelFiltersLabelFormatter("level3"),
        accessor: "l3_name",
        disableFilters: true,
        width: 200,
      },
      {
        Header:
          props?.filterLabels.level4 || levelFiltersLabelFormatter("level4"),
        accessor: "l4_name",
        disableFilters: true,
        width: 200,
      },
      {
        Header:
          props?.filterLabels.level5 || levelFiltersLabelFormatter("level5"),
        accessor: "l5_name",
        disableFilters: true,
        width: 200,
      },
      {
        Header:
          props?.filterLabels.level6 || levelFiltersLabelFormatter("level6"),
        accessor: "l6_name",
        disableFilters: true,
        width: 200,
      },
      {
        Header: "Product Tag",
        accessor: "tag",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.tag?.value}
            changeHandler={(term, id) =>
              fetchDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        disableSortBy: true,
      },
      {
        Header: "Store Number",
        accessor: "store_code",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.store_code?.search}
            placeholder={"Search in bulk.."}
            changeHandler={(term, id) =>
              fetchDataWithSearchTerm(term, id, "array")
            }
          />
        ),
        disableSortBy: true,
      },
      {
        Header: "Store Name",
        accessor: "store_name",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.store_name?.value}
            changeHandler={(term, id) =>
              fetchDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        disableSortBy: true,
      },
      // {
      //   Header: "Assortment Group",
      //   accessor: "assortment_group",
      //   width: 250,
      //   Filter: (instance) => (
      //     <ColumnFilterServerSide
      //       {...instance}
      //       searchTermValue={searchTermReq?.assortment_group?.value}
      //       changeHandler={(term, id) =>
      //         fetchDataWithSearchTerm(term, id, "string")
      //       }
      //     />
      //   ), // sticky: "left"
      // },
      // {
      //   Header: "Size",
      //   accessor: "size",
      //   Filter: (instance) => (
      //     <ColumnFilterServerSide
      //       {...instance}
      //       searchTermValue={searchTermReq?.size?.value}
      //       changeHandler={(term, id) =>
      //         fetchDataWithSearchTerm(term, id, "string")
      //       }
      //     />
      //   ),
      // },
      {
        Header: "Avg. Min per store",
        accessor: "min_stock",
        Cell: (instance) => (
          <InputCell
            shouldOnBlur
            roundOffOnChange
            min={0}
            max={Number(instance.row?.original?.max_stock)}
            step={1}
            type="number"
            {...instance}
            debouncedChangeHandler={(
              rowIndex,
              columnId,
              value,
              rowId,
              subRow,
              p_row
            ) => {
              setStoreData((oldData) => {
                if (oldData?.length) {
                  let newData = cloneDeep(oldData);
                  newData[rowIndex][columnId] = value;
                  return newData;
                } else {
                  return oldData;
                }
              });

              setInitialIndex(RTinstance.state.pageIndex);
              setIsDisabled((prev) => {
                if (prev) {
                  return false;
                }
              });
              setUpdatedRows((old) => {
                return [...old, p_row.original.constraint_id];
              });
            }}
          />
        ),
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide
            {...instance}
            minSearchTermValue={searchTermReq?.min_stock?.value?.min}
            maxSearchTermValue={searchTermReq?.min_stock?.value?.max}
            clickHandler={(min_stock, max_stock, id) =>
              fetchDataWithSearchTerm([min_stock, max_stock], id, "range")
            }
          />
        ),
        filter: "between",
        disableSortBy: true,
      },
      {
        Header: "Avg. Max.per store",
        accessor: "max_stock",
        Cell: (instance) => (
          <InputCell
            shouldOnBlur
            roundOffOnChange
            min={Number(instance.row?.original?.min_stock)}
            step={1}
            type="number"
            {...instance}
            debouncedChangeHandler={(
              rowIndex,
              columnId,
              value,
              rowId,
              subRow,
              p_row
            ) => {
              setStoreData((oldData) => {
                if (oldData?.length) {
                  let newData = cloneDeep(oldData);
                  newData[rowIndex][columnId] = value;
                  return newData;
                } else {
                  return oldData;
                }
              });

              setInitialIndex(RTinstance.state.pageIndex);
              setIsDisabled((prev) => {
                if (prev) {
                  return false;
                }
              });
              setUpdatedRows((old) => {
                return [...old, p_row.original.constraint_id];
              });
            }}
          />
        ),
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide
            {...instance}
            minSearchTermValue={searchTermReq?.max_stock?.value?.min}
            maxSearchTermValue={searchTermReq?.max_stock?.value?.max}
            clickHandler={(min_stock, max_stock, id) =>
              fetchDataWithSearchTerm([min_stock, max_stock], id, "range")
            }
          />
        ),
        filter: "between",
        disableSortBy: true,
      },
      {
        Header: "WOS",
        accessor: "wos",
        Cell: (instance) => (
          <InputCell
            roundOffOnChange
            min={0}
            step={1}
            type="number"
            {...instance}
            debouncedChangeHandler={(
              rowIndex,
              columnId,
              value,
              rowId,
              subRow,
              p_row
            ) => {
              setStoreData((oldData) => {
                if (oldData?.length) {
                  let newData = cloneDeep(oldData);
                  newData[rowIndex][columnId] = value;
                  return newData;
                } else {
                  return oldData;
                }
              });

              setInitialIndex(RTinstance.state.pageIndex);
              setUpdatedRows((old) => {
                return [...old, p_row.original.constraint_id];
              });
              setIsDisabled((prev) => {
                if (prev) {
                  return false;
                }
              });
            }}
          />
        ),
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide
            {...instance}
            minSearchTermValue={searchTermReq?.wos?.value?.min}
            maxSearchTermValue={searchTermReq?.wos?.value?.max}
            clickHandler={(min_stock, max_stock, id) =>
              fetchDataWithSearchTerm([min_stock, max_stock], id, "range")
            }
          />
        ),
        filter: "between",
        disableSortBy: true,
      },
      {
        Header: 'Store Grade',
        accessor: 'store_grade',
        Cell: (instance) => (
          <SelectCell 
            {...instance}
            dropdownOptions={storeGradeOptions}
            changeHandler={(rowIndex, columnId, value, p_row) => {
              setStoreData((oldData) => {
                if (oldData?.length) {
                  let newData = cloneDeep(oldData);
                  newData[rowIndex][columnId] = value;
                  return newData;
                } else {
                  return oldData;
                }
              });

              setInitialIndex(RTinstance.state.pageIndex);
              setUpdatedRows((old) => {
                return [...old, p_row.original.constraint_id];
              });
              setIsDisabled((prev) => {
                if (prev) {
                  return false;
                }
              });
            }}
          />
        ), 
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.store_grade?.value}
            changeHandler={(term, id) =>
              fetchDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        disableSortBy: true,
      },
      {
        Header: "Updated By",
        accessor: "updated_by",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.updated_by?.value}
            changeHandler={(term, id) =>
              fetchDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        width: 250,
        disableSortBy: true,
      },
      {
        Header: "Last Modified",
        accessor: "updated_at",
        disableFilters: true,
        width: 250,
        disableSortBy: true,
      },
    ],
    [RTinstance, searchTermReq, storeGradeOptions]
  );

  useEffect(() => {
    return () => {
      setDownloadExcel(false)
      props.resetAll();
    };
  }, []);

  useEffect(() => {
    if (props.isUpdated && isButtonClicked) {
      setShouldLoading(false);
      setLoaderMessage(null);
      setInitialIndex(0);
      setPrevIndex(0);
      setRTinstance(null)
      setStoreData([])
      props.fetchDefaultConstraintsData({
        filters: filters,
        rowCount: rowsCount,
        rowIndex: 0,
        styleIndex: 0,
      });
    }
  }, [props.isUpdated]);

  useEffect(() => {
    if (
      (!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) &&
      !isEmpty(filters)
    ) {
      setStoreData([])
      setRTinstance(null)
      setInitialIndex(0)
      setPrevIndex(0)
      retainCheckboxState()
      props.fetchDefaultConstraintsData({
        filters: filters,
        rowCount: rowsCount,
        rowIndex: 0,
        searchTermReq,
        sortReq,
      });
    }
  }, [searchTermReq, sortReq]);
 
  useEffect(() => {
    if (props.isFilterClicked) {
      setSearchTermReq({});
      setSortReq({});
      setIsFirstCall(true);
      //props.resetIsFilterClicked();
    }
    if (isEmpty(props?.storeData)) {
      setInitialIndex(0);
      setPrevIndex(0);
      setSetAll(false);
      setInitialSelectedRows({});
    }
    setDownloadExcel(false)

    if (props?.storeData?.length) {
        if (RTinstance?.data?.length) {
            setStoreData([...RTinstance.data, ...props?.storeData])
        }
        else {
            setStoreData(props?.storeData)
        }
    }
    setStoreGradeOptions(props.storeGradeOptions)
  }, [props?.storeData])

  useEffect(() => {
    setDownloadExcel(false)
    if (setAll && storeData.length > rowsCount) {
      let initialSelectedRows = {};
      range(
        Number(storeData.length) - Number(rowsCount),
        storeData.length
      ).forEach((item, index) => {
        initialSelectedRows[`${item}`] = true;
      });
      setInitialSelectedRows((oldData) => {
        return {
          ...oldData,
          ...initialSelectedRows,
        };
      });
    }
  }, [storeData]);

  useEffect(() => {
    if (maxPerStoreSA == "") setShowMessage(false);
    else if (maxPerStoreSA == 0) setShowMessage(true);
    else setShowMessage(false);
  }, [maxPerStoreSA]);

  const applyConstraints = (p_setAll, p_rows) => {
    let data;
    setSortReq({});
    setSearchTermReq({});
    setStoreData([]);
    if (p_setAll) {
      let req = {
        ...props?.storeRequest,
        screen_type: "set_all",
        store_grade: storeGradeSA?.value,
      };
      if (wosSA || wosSA == 0) {
        req["wos"] = wosSA;
      }
      if (minPerStoreSA || minPerStoreSA == 0) {
        req["min_stock"] = minPerStoreSA;
      }
      if (maxPerStoreSA || maxPerStoreSA == 0) {
        if (showMessage) req["min_stock"] = "0";
        req["max_stock"] = maxPerStoreSA;
      }

      // In case blank data passed consider it to be 0
      if (minPerStoreSA === "") req["min_stock"] = "0"
      if (maxPerStoreSA === "") req["max_stock"] = "0"
      if (wosSA === "") req["wos"] = "0"
      const { ...updatedReq } = req;
      props.updateTableData(updatedReq);
      setIsDisabled(true);
      setIsButtonClicked(true);
      setShouldLoading(true);
      setLoaderMessage("Applying your edits");
      setIsFirstCall(true)
    } else {
      if (p_rows.length) {
        data = p_rows;
        if (!updatedRows.length) {
          setIsDisabled(true);
        }
      } else {
        let uniqueUpdatedRows = [...new Set(updatedRows)];
        data = storeData?.filter((val) => {
          return uniqueUpdatedRows.includes(val.constraint_id)}
        );
        setUpdatedRows([]);
        setIsDisabled((prev) => {
          if (!prev) {
            return true;
          }
        });
      }
      for (let i = 0; i < data.length; i++) {
        if (Number(data[i].min_stock || 0) > Number(data[i].max_stock || 0)) {
          Notify.error("Make sure min is less than or equal to max !!");
          return;
        }
        // In case blank or null data passed consider it to be 0
        if (!data[i].min_stock || data[i].min_stock === "") data[i].min_stock = "0"
        if (!data[i].max_stock || data[i].max_stock === "") data[i].max_stock = "0"
        if (!data[i].wos || data[i].wos === "") data[i].wos = "0"
      }
      let req = {
        data: data,
      };
      setInitialIndex(0)
      setPrevIndex(0)
      props.updateTableData(req);
      setIsButtonClicked(true);
      setShouldLoading(true);
      setIsFirstCall(true)
      setLoaderMessage("Applying your edits");
      RTinstance.setObject([])
    }
  };

  const retainCheckboxState = () => {
    let initialSelectedRows = {}
    Object.keys(RTinstance.state.selectedRowIds).forEach((item, index) => {
      initialSelectedRows[`${item}`] = true
    })
    setInitialSelectedRows(initialSelectedRows)
  }

  const fetchData = (index) => {
    if (prevIndex >= index || nextIndex >= totalCount) 
      return;
    retainCheckboxState()
    setPrevIndex(index);
    setInitialIndex(Math.floor(nextIndex / 10));
    props.fetchDefaultConstraintsData({
      filters: filters,
      rowCount: rowsCount,
      rowIndex: nextIndex,
      searchTermReq,
      sortReq,
    });
  };

  const fetchDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
    if (p_type === "array") {
      if (split_type) {
        p_term = p_term.replace(/\s*,\s*/g, ",");
      }
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old);
        var id = p_term.split(split_type ? split_type : " ");
        if (p_term?.trim() === 0 || p_term?.trim() === '') {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
        } else {
          l_searchTermReq[p_id] = {
            type: p_type,
            values: id,
            search: p_term,
          };
        }
        return {
          ...l_searchTermReq,
        };
      });
    } else if (Array.isArray(p_term)) {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old);
        let min_stock = p_term[0] == "" ? null : p_term[0];
        let max_stock = p_term[1] == "" ? null : p_term[1];
        if (min_stock == null && max_stock == null) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
        } else {
          l_searchTermReq[p_id] = {
            type: p_type,
            value: {
              min: min_stock,
              max: max_stock,
            },
          };
        }
        return {
          ...l_searchTermReq,
        };
      });
    } else {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old);
        if (p_term?.trim() === 0 || p_term?.trim() === '') {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
        } else {
          l_searchTermReq[p_id] = {
            type: p_type,
            value: p_term,
          };
        }
        return {
          ...l_searchTermReq,
        };
      });
    }
    isFirstCall && setIsFirstCall(false);
    setUpdatedRows([]);
    // setIsDisabled((prev) => {
    //   if (!prev) {
    //     return true;
    //   }
    // });
  };

  const fetchDataWithSorting = (p_sort) => {
    setSortReq(p_sort);
    isFirstCall && setIsFirstCall(false);
    setUpdatedRows([]);
    if (p_sort?.length) {
      // setIsDisabled((prev) => {
      //   if (!prev) {
      //     return true;
      //   }
      // });
    }
  };

  useEffect(() => {
    setTotalCount(props.totalCount);
    setIndex(props.nextIndex);
  }, [props.totalCount, props.nextIndex]);

  const prepInput = React.useMemo(
    () => [
      {
        id: 1,
        isRequired: false,
        label: "Avg. Min per store",
        name: "min_stock",
        className: "setAll__input__center_4",
        value: minPerStoreSA,
        onChange: (e) => {
          setMinPerStoreSA(Math.round(e.target.value).toString());
        },
        onBlur: (e) => {
          let l_minPerStoreSA = minPerStoreSA;
          if (maxPerStoreSA) {
            if (!Number(maxPerStoreSA)) l_minPerStoreSA = 0;
            else {
              l_minPerStoreSA = Math.min(
                Number(maxPerStoreSA),
                l_minPerStoreSA
              );
              l_minPerStoreSA = l_minPerStoreSA === 0 ? "0" : l_minPerStoreSA;
            }
          }
          setMinPerStoreSA(l_minPerStoreSA);
        },
        input: true,
        type: "number",
        min: 0,
        step: 1,
      },
      {
        id: 2,
        isRequired: false,
        label: "Avg. Max.per store",
        name: "max_stock",
        className: "setAll__input__center_4",
        value: maxPerStoreSA,
        onChange: (e) => {
          setMaxPerStoreSA(Math.round(e.target.value).toString());
        },
        onBlur: (e) => {
          let l_maxPerStoreSA = maxPerStoreSA;
          if (minPerStoreSA) {
            l_maxPerStoreSA = Math.max(Number(minPerStoreSA), l_maxPerStoreSA);
            l_maxPerStoreSA = l_maxPerStoreSA === 0 ? "0" : l_maxPerStoreSA;
          }
          setMaxPerStoreSA(l_maxPerStoreSA);
        },
        input: true,
        type: "number",
        min: 0,
        step: 1,
      },
      {
        id: 3,
        isRequired: false,
        label: "WOS",
        name: "wos",
        className: "setAll__input__center_4",
        value: wosSA,
        onChange: (e) => {
          let val = e.target.value;
          if (Number(val) < 0) {
            val = 0;
          }
          setwosSA(Math.round(val).toString());
        },
        input: true,
        type: "number",
        min: 0,
      },
      {
        id: 4,
        isRequired: false,
        label: 'Store Grade',
        name: 'store_grade',
        className: "setAll__input__center_4",
        value: storeGradeSA,
        onChange: (val) => {
          setStoreGradeSA(val);
        },
        placeHolder: `Select Store Grade`,
        options: storeGradeOptions,
        isMultiSelect: false,
        hideSelectAll: true,  
      },
    ],
    [minPerStoreSA, maxPerStoreSA, wosSA, storeGradeSA, storeGradeOptions]
  );

  const handleSetAllOk = () => {
    if (
      minPerStoreSA != null &&
      maxPerStoreSA != null &&
      Number(minPerStoreSA) > Number(maxPerStoreSA)
    ) {
      Notify.error("Make sure min is less than or equal to max !!");
      return;
    }
    let setAllRows = Object.keys(RTinstance.state.selectedRowIds);
    setAll && setSetAll(false);
    setInitialIndex(RTinstance.state.pageIndex);
    setInitialSelectedRows({});
    let updatedRows = [];
    setStoreData((oldData) => {
      let newData = cloneDeep(oldData);
      setAllRows.forEach((setAllRow) => {
        updatedRows.push(newData[setAllRow]?.["unique"]);
        if (minPerStoreSA) newData[setAllRow]["min_stock"] = minPerStoreSA;
        if (maxPerStoreSA) {
          if (showMessage) newData[setAllRow]["min_stock"] = "0";
          newData[setAllRow]["max_stock"] = maxPerStoreSA;
        }
        if (wosSA || wosSA == 0) newData[setAllRow]["wos"] = wosSA;
        if (storeGradeSA) {
          newData[setAllRow]["store_grade"] = storeGradeSA;
        }
      });
      return newData;
    });
    let newData = cloneDeep(storeData);
    newData = newData.filter((val, indx) => setAllRows.includes(String(indx)));
    newData.forEach((data) => {
      if (minPerStoreSA) data["min_stock"] = minPerStoreSA;
      if (maxPerStoreSA) {
        if (showMessage) data["min_stock"] = "0";
        data["max_stock"] = maxPerStoreSA;
      }
      if (wosSA) data["wos"] = wosSA;
      if (storeGradeSA) {
        data["store_grade"] = storeGradeSA;
      }
    });
    if (minPerStoreSA || maxPerStoreSA || wosSA || storeGradeSA) {
      let l_boolean = storeData?.length == setAllRows?.length;
      applyConstraints(l_boolean, newData);
    }
  };

  const handleSetAllCancel = () => {
    setShowSetAll(false);
  };

  const handleClose = () => {
    showSetAll && setShowSetAll(false);
  };

  const setAllHandler = (p_instance) => {
    if (Object.keys(p_instance.state.selectedRowIds).length > 0) {
      setShowMessage(false);
      setShowSetAll(true);
      setMinPerStoreSA(null);
      setMaxPerStoreSA(null);
      setwosSA(null);
      setStoreGradeSA(null);
    } else {
      Notify.error(
        `Please select atleast 1 store to use Set All functionality!!`
      );
    }
  };

  const applyFilters = (p_payload) => {
    setRTinstance(null)
    setStoreData([]);
    setInitialIndex(0)
    setPrevIndex(0);
    setIsFilterClicked(true)
    setSearchTermReq({})
    setInitialSelectedRows({})
    setSortReq({})
    setFilters(p_payload);
    setSetAll(false)
    setIsFirstCall(true)
    props.fetchDefaultConstraintsData({
      filters: p_payload,
      rowCount: rowsCount,
      rowIndex: 0,
    });
  };

  const resetTableData = () => {
    setStoreData([]);
    setSearchTermReq(null);
    setSortReq(null);
    props.resetAll();
    setSetAll(false)
  };

  const updateSetAll = (p_instance) => {
    if (p_instance?.isAllRowsSelected && !setAll) {
      setSetAll(true)
    }
    if (!p_instance.isAllRowsSelected && Object.keys(p_instance.state.selectedRowIds).length == 0 && setAll) {
      setSetAll(false)
    }
  }

  const generateExcel = () => {
    setExcelData(storeData)
    setDownloadLoading(true)
    setDownloadExcel(false)
    props.generateExcel({ ...filters, row_index: nextIndex, row_count: rowsCount,  searchTermReq: searchTermReq, sortReq: sortReq?.[0] })
}

const getExcelData = () => {
    let req = filters
    req["row_index"] = downloadNextIndex
    req["row_count"] = rowsCount
    req["searchTermReq"] = searchTermReq
    req["sortReq"] = sortReq

    props.generateExcel(req)
}

useEffect(() => {
    if (props.excelData?.length > 0) {
        if (!isEmpty(excelData)) {
            setExcelData([...excelData, ...props.excelData]);
        }
        else {
            setExcelData([...props.excelData]);
        }
    }
    else {
        setExcelData([])
    }
    setTotalCountExcel(props.totalCountExcel)
    setNextIndexExcel(props.nextIndexExcel)
}, [props.excelData,props.totalCountExcel, props.nextIndexExcel])

useEffect(() => {
    if (downloadExcelData.length) {
        setExcelData(excelData.concat(downloadExcelData))
        // setDownloadExcel(true)
    }
}, [downloadExcelData])

useEffect(() => {
    if (downloadExcelLoading && downloadNextIndex < downloadTotalCount) {
        getExcelData()
    }
    else if (downloadNextIndex > downloadTotalCount) {
        formatExcelData(excelData.concat(downloadExcelData)) 
    }
}, [downloadNextIndex])

const formatExcelData = (downloadData) => {
    let data = []
    let obj = {}
    downloadData.forEach(item => {
        obj = {}
        obj["Channel"] = item.l1_name
        obj["Department"] = item.l2_name
        obj["Class"] = item.l3_name
        obj["Sub-Class"] = item.l4_name
        obj["Silhouette"] = item.l5_name
        obj["Collection"] = item.l6_name
        obj["Product Tag"] = item.tag
        obj["Store Number"] = item.store_code
        obj["Store Name"] = item.store_name
        // obj["Assortment Group"] = item.assortment_group
        //obj["Size"] = item.size
        obj["Avg. Min per store"] = item.min_stock
        obj["Avg. Max per store"] = item.max_stock
        obj["WOS"] = item.wos
        obj["Store Grade"] = Array.isArray(item.store_grade) ? item.store_grade?.[0].value : item.store_grade
        obj["Updated By"] = item.updated_by
        obj["Last Modified"] = item.updated_at
        data.push(obj)
    })
    setExcelData(data)
    setTimeout(() => {
        setDownloadLoading(false)
        setDownloadExcel(true)
    }, 1000)
    
    resetDownloadExcel()
}

useEffect(() => {
    if (downloadExcelError) {
        setDownloadLoading(false)
        // Notify.error(downloadExcelError)
        resetDownloadExcel()
    }
}, [downloadExcelError])

const closeDownloadModal = () => {
    setDownloadLoading(false)
    resetDownloadExcel()
}

  return (
    <>
      <div>
        {/* <Notification /> */}
        <DefaultConstraintsFilter
          resetTableData={resetTableData}
          applyFilters={applyFilters}
          setFilters={setFilters}
        />
        {showSetAll && (
          <SetAllModal
            customModalStyle={{ minWidth: "75vw", height: "50rem" }}
            showMessage={showMessage}
            message={message}
            filter={prepInput}
            // Absolute added to make the error message centered.
            messageStyle={{
              margin: "auto",
              color: "red",
              position: "absolute",
              left: "30%",
              top: "50%",
            }} 
            handleOk={handleSetAllOk}
            handleCancel={handleSetAllCancel}
            handleClose={() => handleClose()}
          />
        )}
        <PageLoader
          loaderMessage={loaderMessage}
          loader={props.loading || shouldLoading}
          gridLoader={true}
        >
          <DownloadProgressBar
            nextIndex={excelData?.length}
            totalCount={downloadTotalCount || totalCount}
            showModal={downloadExcelLoading}
            closeModal={closeDownloadModal}
          />
          {props.storeError ? (
            <div className="error">Something Went Wrong!!</div>
          ) : (
            <>
              {props?.storeData?.length > 0 && (
                <div className="d-flex justify-content-end mr-4 mb-2">
                  <ExportFile
                    downloadExcelData={downloadExcel}
                    callFunc={generateExcel}
                    text={"Excel"}
                    data={excelData}
                    fileName={`Default_Configuration_${new Date().getTime()}`}
                    type={1}
                    formateXLSX={true}
                    excelColumnsToBeFormatted={DEFAULT_CONSTRAINTS_COLUMNS_FORMATTING}
                  />
                </div>
              )}
              <ReactTableWithPlugins
                shouldPagination
                hideDropdown
                totalRecordsLen={storeData?.length}
                data={storeData}
                columns={columns}
                setIsDisabled={setIsDisabled}
                renderMarkup="TableMarkup"
                tableWrapperStyle={{
                  height: "fit-content",
                  maxHeight: "50rem",
                }}
                headerWrapperStyle={{ position: "sticky", top: 0, zIndex: "4" }}
                keyRT="constraintsPageTable"
                getInstanceOnMount={(instance) => {
                  updateSetAll(instance)
                  setRTinstance(instance);
                }}
                getManualSortByOptions={(instance) => {
                  fetchDataWithSorting(instance);
                }}
                fetchData={(data) => fetchData(data)}
                initialPageIndex={initialIndex}
                initialSelectedRows={initialSelectedRows}
                features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                manualSortBy={true}
                tableId="constraints_store"
                totalCount={totalCount}
              />
            </>
          )}
        </PageLoader>
      </div>
      <div div style={{ textAlign: "center", margin: "2rem" }}>
        <button
          disabled={isDisabled}
          onClick={() => applyConstraints(false, [])}
          style={{ margin: "2px 1rem" }}
          class="btn btn-primary center"
          title="Apply All filters"
        >
          Apply All
        </button>
        <button
          style={{ margin: "2px 1rem" }}
          className="btn btn-primary"
          onClick={() => setAllHandler(RTinstance)}
        >
          Set All
        </button>
      </div>
    </>
  );
};

const mapStateToProps = ({ constraints }) => ({
  storeData: constraints.defaultStoreData,
  filterLabels: constraints.filterlabels,
  isUpdated: constraints.isUpdated,
  storeError: constraints.storeError,
  loading: constraints?.storeloading,
  totalCount: constraints?.totalCount,
  nextIndex: constraints?.nextIndex,
  storeRequest: constraints?.storeRequest,
  styleIndex: constraints?.styleIndex,
  pageIndex: constraints?.pageIndex,
  downloadExcelData: constraints.excelData,
  totalCountExcel: constraints.totalCountExcel,
  nextIndexExcel: constraints.nextIndexExcel,
  downloadNextIndex: constraints.downloadNextIndex,
  downloadTotalCount: constraints.downloadTotalCount,
  downloadExcelError: constraints.downloadExcelError,
  storeGradeOptions: constraints.storeGradeOptions,
});

const mapDispatchToProps = (dispatch) => ({
  updateTableData: (payload) => dispatch(updateDefaultData(payload)),
  resetAll: () => dispatch(resetAll()),
  resetStyleIndex: () => dispatch(resetStyleIndex()),
  fetchDefaultConstraintsData: (payload) =>
    dispatch(fetchDefaultConstraintsData(payload)),
  generateExcel: (payload) => dispatch(generateExcel(payload)),
  resetDownloadExcel: () => dispatch(resetDownloadExcel()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultConstraints);
