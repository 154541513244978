import React, { useEffect, useState } from "react";
import { intersectionWith, isEmpty, isEqual } from "lodash";
import { connect } from "react-redux";
import "../../components/Filters/Filters.css"
import {
  resetFiltersOptions,
  fetchFilterData,
  fetchDefaultConstFilterData,
} from "../../containers/Constraints/ConstraintsAction";
import * as Notify from "../../components/Notification/Notifications"
import { handleSelectAll } from "../../utils/SelectAllHandler/SelectAllHander";
import { getUserPreferenceFilter, setUserPreferenceFilter, getLevelFiltersForUserPreference, getDefaultFiltersCondition, isMandatory } from "../../utils/commonUtilities";
import MultiSelect from "../../components/Filters/MultiSelect";
import Select from "../../components/Filters/SingleSelect";

const ConstraintsFilter = React.memo(
  ({
    // channelOptions,
    // departmentOptions,
    // level3Options,
    // dcsOptions,
    // styleOptions,
    // colourOptions,
    // sizeOptions,
    ...props
  }) => {
    const [channel, setChannel] = useState(null);
    const [department, setDepartment] = useState(null);
    const [level3, setLevel3] = useState(null);
    const [assortInd, setAssortInd] = useState(null);
    const [size, setSize] = useState(null);
    const [store, setStore] = useState(null)
    const [request, setRequest] = useState(null)
    const [channelOptions, setChannelOptions] = useState(null);
    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [level3Options, setLevel3Options] = useState(null);
    const [assortIndOptions, setAssortIndOptions] = useState(null);
    const [sizeOptions, setSizeOptions] = useState(null);
    const [storeOptions, setStoreOptions] = useState(null);
    const [productTag, setProductTag] = useState(null)
    const [productTagOptions, setProductTagOptions] = useState(null);

    const [level4, setLevel4] = useState(null);
    const [level4Options, setLevel4Options] = useState(null);
    const [level5, setLevel5] = useState(null);
    const [level5Options, setLevel5Options] = useState(null);
    const [level6, setLevel6] = useState(null);
    const [level6Options, setLevel6Options] = useState(null);

    useEffect(() => {
      setChannelOptions(() => props.channelOptions);
    }, [props.channelOptions]);


    useEffect(() => {
      setDepartmentOptions(() => props.departmentOptions);
    }, [props.departmentOptions]);

    useEffect(() => {
      setLevel3Options(() => props.level3Options);
    }, [props.level3Options]);

    useEffect(() => {
      setLevel4Options(() => props.level4Options);
    }, [props.level4Options]);

    useEffect(() => {
      setLevel5Options(() => props.level5Options);
    }, [props.level5Options]);

    useEffect(() => {
      setLevel6Options(() => props.level6Options);
    }, [props.level6Options]);

    useEffect(() => {
      setAssortIndOptions(() => props.assortIndOptions);
    }, [props.assortIndOptions]);

    useEffect(() => {
      setSizeOptions(() => props.sizeOptions);
    }, [props.sizeOptions]);

    useEffect(() => {
      setStoreOptions(() => props.storeOptions);
    }, [props.storeOptions]);

    useEffect(() => {
      setProductTagOptions(() => props.productTagOptions);
    }, [props.productTagOptions]);

    useEffect(() => {
      getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, { onl1Change: onChannelChange, onl2Change: onDepartmentChange, onl3Change: onLevel3Change })
      props.fetchFilterData({}, { key: 'l1' });
    }, []);

    const resetRestFilters = (p_index) => {
      size && setSize(null);
      store && setStore(null);
      sizeOptions && setSizeOptions(null)
      storeOptions && setStoreOptions(null)
      productTag && setProductTag(null)
      productTagOptions && setProductTagOptions(null)
      assortInd && setAssortInd(null)
      assortIndOptions && setAssortIndOptions(null)
      if (p_index == 0) {
        department && setDepartment(null);
        departmentOptions && setDepartmentOptions(null)
      }
      if (p_index == 0 || p_index == 1) {
        level3 && setLevel3(null);
        level3Options && setLevel3Options(null)
      }
      if (p_index == 0 || p_index == 1 || p_index == 2 ) {
        level4 && setLevel4(null);
        level4Options && setLevel4Options(null)
      }
      if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 ) {
        level5 && setLevel5(null);
        level5Options && setLevel5Options(null)
      }
      if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index == 4) {
        level6 && setLevel6(null);
        level6Options && setLevel6Options(null)
      }
      // if (p_index == 0 || p_index == 1 || p_index == 2) {
      //   dcs && setDCS(null);
      //   dcsOptions && setDCSOptions(null)
      // }
      // if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3) {
      //   level5 && setLevel5(null);
      //   level5Options && setLevel5Options(null)
      // }
      // if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index == 4) {
      //   level6 && setLevel6(null);
      //   level6Options && setLevel6Options(null)
      // }
      // if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index == 4 || p_index == 5) {
      //   level7 && setLevel7(null);
      //   level7Options && setLevel7Options(null)
      // }

    };

    const onChannelChange = (val, p_userPreference) => {
      setRequest(old => {
        return {
          ...old,
          'level2': null,
          'level3': null,
          'assortment_group': null,
          'size': null,
          'store_code': null,
        }
      })
      setChannel(val);
      resetRestFilters(0);
      if (p_userPreference?.user_preference) {
        props.fetchFilterData({ level1: [val] }, { key: 'l10' });
      }
      else {
        props.fetchFilterData({ level1: [val] }, { key: 'l2' });
      }
    };
    const onDepartmentChange = (val, event, that) => {
      let valuesArray = handleSelectAll(val, event, that)
      setDepartment(valuesArray[1]);
      setRequest(old => {
        return {
          ...old,
          'level2': valuesArray[0],
          'level3': null,
          'assortment_group': null,
          'size': null,
          'store_code': null,
        }
      })
      let req_val = intersectionWith(valuesArray[0], departmentOptions, isEqual);

      resetRestFilters(1);
      //API call to get next filter
      if (event?.user_preference) {
        props.fetchFilterData({ level1: [event?.l1_name], level2: Array.isArray(val) ? val : [val] }, { key: 'l3' });
      }
      else {
        props.fetchFilterData({ level1: [channel], level2: req_val }, { key: 'l3' });
      }
    };
    const onLevel3Change = (val, event, that) => {
      let valuesArray = handleSelectAll(val, event, that)

      setLevel3(valuesArray[1]);
      setRequest(old => {
        return {
          ...old,
          'level3': valuesArray[0],
          'assortment_group': null,
          'size': null,
          'store_code': null,
        }
      })
      resetRestFilters(2);
      //API call to get next filter
      let req_val = intersectionWith(valuesArray[0], level3Options, isEqual);

      let req = {
        level1: [channel],
        level3: req_val
      }
      if (department) {
        req['level2'] = [department]
      }
      if (event?.user_preference) {
        let l_req = {
          level1: [event?.l1_name],
          level2: [event?.l2_name],
          level3: [val]
        }
        props.fetchFilterData(l_req, { key: 'l4' });
      }
      else {
        props.fetchFilterData(req, { key: 'l4' });
      }
    };

    const onSizeChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      // let value = intersectionWith(val, sizeOptions, isEqual);
      setRequest(old => {
        return {
          ...old,
          'size': valuesArray[0]
        }
      })
      setSize(valuesArray[1]);
    };

    const onStoreChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      // let value = intersectionWith(val, sizeOptions, isEqual);
      setRequest(old => {
        return {
          ...old,
          'store_code': valuesArray[0]
        }
      })
      setStore(valuesArray[1]);
    };

    const onProductTagChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      setRequest(old => {
        return {
          ...old,
          'tag': valuesArray[0]
        }
      })
      setProductTag(valuesArray[1]);
    };

    const onAssortIndChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      setRequest(old => {
        return {
          ...old,
          'assortment_group': valuesArray[0]
        }
      })
      setAssortInd(valuesArray[1]);
    }

    const onDCSChange = (val, event, that) => {
      let valuesArray = handleSelectAll(val, event, that)

      setLevel4(valuesArray[1]);
      setRequest(old => {
        return {
          ...old,
          'level4': valuesArray[0],
          'level5': null,
          'level6': null,
          'assortment_group': null,
          'size': null,
          'store_code': null,
        }
      })
      let req_val = intersectionWith(valuesArray[0], level4Options, isEqual);
      resetRestFilters(3);
      let req = {
        level1: [channel],
        level4: req_val
      }
      if (department) {
        req['level2'] = [department]
      }
      if (level3) {
        req['level3'] = [level3]
      }

      //API call to get next filter
       props.fetchFilterData(req, { key: 'l9' });
    };
    const onLevel5Change = (val, event, that) => {
      let valuesArray = handleSelectAll(val, event, that)

      setLevel5(valuesArray[1]);
      setRequest(old => {
        return {
          ...old,
          'level5': valuesArray[0],
          'level6': null,
          'assortment_group': null,
          'size': null,
          'store_code': null,
        }
      })
      let req_val = intersectionWith(valuesArray[0], level5Options, isEqual);

      resetRestFilters(4);
      let req = {
        level1: [channel],
        level5: req_val
      }
      if (department) {
        req['level2'] = [department]
      }
      if (level3) {
        req['level3'] = [level3]
      }
      if (level4) {
        req['level4'] = [level4]
      }

      //API call to get next filter
      props.fetchFilterData(req, { key: 'l7' });
    };

    const onLevel6Change = (val, event, that) => {
      let valuesArray = handleSelectAll(val, event, that)

      setLevel6(valuesArray[1]);
      setRequest(old => {
        return {
          ...old,
          'level6': valuesArray[0],
          'assortment_group': null,
          'size': null,
          'store_code': null,
        }
      })
      let req_val = intersectionWith(valuesArray[0], level6Options, isEqual);

      resetRestFilters(5);
      let req = {
        level1: [channel],
        level6: req_val
      }
      if (department) {
        req['level2'] = [department]
      }
      if (level3) {
        req['level3'] = [level3]
      }
      if (level4) {
        req['level4'] = [level4]
      }
      if (level5) {
        req['level5'] = [level5]
      }

      props.fetchFilterData(req, { key: 'l8' });
    }
    const applyFilters = () => {
      if (getDefaultFiltersCondition({ l1_name: channel, l2_name: department })) {
        setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, getLevelFiltersForUserPreference({ l1_name: channel, l2_name: department, l3_name: level3 }))
        props.applyFilters({
          channel: channel,
          department: department,
          level3: level3,
          level4: level4,
          level5: level5,
          level6: level6,
          assortment_group: request?.assortment_group,
          size: request?.size,
          store_code: request?.store_code,
          tag: request.tag,
        });
      } else {
        Notify.error("Please Select All Mandatory Options!!");
      }
    };

    const resetFilters = () => {
      setRequest(null)
      setChannel(null);
      setDepartment(null);
      setLevel3(null);
      setSize(null);
      setStore(null);
      setAssortInd(null)
      setProductTag(null);
      setLevel4(null);
      setLevel5(null);
      setLevel6(null);
      props.resetTableData();
      props.resetFiltersOptions([]);
      props.setFilters(null)
    };

    return (
      <>
        <section className="row section">
          {/* <Notification /> */}
          <div className="col-md-3 required">
            <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1}</label>
            <Select
              name="channel"
              value={channel}
              onChange={onChannelChange}
              placeHolder={`Select ${props.filterLabels?.level1}`}
              options={channelOptions?.length ? channelOptions : []}
            />
          </div>
          <div className={`col-md-3 ${isMandatory("l2_name")}`}>
            <label className="fnt-md fnt-bold">{props.filterLabels?.level2}</label>
            <MultiSelect
              name="department"
              dropdownValue={department}
              onDropDownChange={onDepartmentChange}
              placeHolder={`Select ${props.filterLabels?.level2}`}
              optionsArray={departmentOptions?.length ? departmentOptions : []}
            />
          </div>
          <div className={`col-md-3`}>
            <label className="fnt-md fnt-bold">{props.filterLabels?.level3}</label>
            <MultiSelect
              name="level3"
              dropdownValue={level3}
              onDropDownChange={onLevel3Change}
              placeHolder={`Select ${props.filterLabels?.level3 ? props.filterLabels?.level3 : ""}`}
              optionsArray={level3Options?.length ? level3Options : []}
            />
          </div>
          <div className={`col-md-3`}>
            <label className="fnt-md fnt-bold">{props.filterLabels?.level4}</label>
            <MultiSelect
              name="level4"
              dropdownValue={level4}
              onDropDownChange={onDCSChange}
              placeHolder={`Select ${props.filterLabels?.level4 ? props.filterLabels?.level4 : ""}`}
              optionsArray={level4Options?.length ? level4Options : []}
            />
          </div>
          <div className={`col-md-3`}>
            <label className="fnt-md fnt-bold">{props.filterLabels?.level5}</label>
            <MultiSelect
              name="level5"
              dropdownValue={level5}
              onDropDownChange={onLevel5Change}
              placeHolder={`Select ${props.filterLabels?.level5 ? props.filterLabels?.level5 : ""}`}
              optionsArray={level5Options?.length ? level5Options : []}
            />
          </div>
          <div className={`col-md-3`}>
            <label className="fnt-md fnt-bold">{props.filterLabels?.level6}</label>
            <MultiSelect
              name="level6"
              dropdownValue={level6}
              onDropDownChange={onLevel6Change}
              placeHolder={`Select ${props.filterLabels?.level6 ? props.filterLabels?.level6 : ""}`}
              optionsArray={level6Options?.length ? level6Options : []}
            />
          </div>
          {/* <div className="col-md-3">
            <label className="fnt-md fnt-bold">Assortment Group</label>
            <MultiSelect
              name="assortmentIndicator"
              dropdownValue={assortInd}
              onDropDownChange={onAssortIndChange}
              placeHolder={"Select Assort Group"}
              optionsArray={assortIndOptions?.length ? assortIndOptions : []}
            />
          </div> */}
          {/* <div className="col-md-3">
            <label className="fnt-md fnt-bold">Size</label>
            <MultiSelect
              name="size"
              dropdownValue={size}
              onDropDownChange={onSizeChange}
              placeHolder={"Select Size"}
              optionsArray={sizeOptions?.length ? sizeOptions : []}
            />
          </div> */}
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Store</label>
            <MultiSelect
              name="store"
              dropdownValue={store}
              onDropDownChange={onStoreChange}
              placeHolder={"Select Store"}
              optionsArray={storeOptions?.length ? storeOptions : []}
            />
          </div>
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Product Tag</label>
            <MultiSelect
              name="product_tag"
              dropdownValue={productTag}
              onDropDownChange={onProductTagChange}
              placeHolder={"Select Product Tag"}
              optionsArray={productTagOptions?.length ? productTagOptions : []}
            />
          </div>
          <div className="col-md-3">
            <button
              onClick={applyFilters}
              className="btn btn-primary filter-constraints-mode"
              title="Apply filters"
            >
              <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
            </button>
            <button
              onClick={() => resetFilters("All")}
              className="btn undo-constraints-mode"
              title="Reset filters"
            >
              <i className="fa fa-undo mr-2"></i>Reset
            </button>
          </div>
          <div className="col-md-3 required fnt-md pt-5" style={{ color: "red" }}>
            <label></label>Fields are mandatory
          </div>
        </section>
      </>
    );
  }
);

const mapStateToProps = ({ constraints }) => ({
  filterLabels: constraints.filterlabels,
  channelOptions: constraints.channelOptions,
  departmentOptions: constraints.defaultConstDepartmentOptions,
  level3Options: constraints.level3Options,
  assortIndOptions: constraints.assortIndOptions,
  sizeOptions: constraints.sizeOptions,
  storeOptions: constraints.storeOptions,
  productTagOptions: constraints.productTagOptions,
  level4Options:constraints.level4OptionsDefaultCons,
  level5Options:constraints.level5OptionsDefaultCons,
  level6Options: constraints.level6OptionsDefaultCons,
});

const mapDispatchToProps = (dispatch) => ({
  resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
  fetchFilterData: (payload, filterKey, filterType) =>
    dispatch(fetchDefaultConstFilterData(payload, filterKey, filterType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConstraintsFilter);
