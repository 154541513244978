import { takeLatest, put, all, call, delay } from "redux-saga/effects";
import {
  getStoreViewData,
  getProductView,
  getProductStoreView,
  editAllocatedUnits,
  allocationPoCreation,
  confirmDataAda,
  updateResQty,
  pastAllocationPoCreation,
  updateFinalizeInfo,
  getPackDetailsApi,
  uploadFile,
  updatePacksEachesForStoresAPI,
  getNewStoreEditReservedDetails,
  editNewStoreReserved,
  disableEditApi,
  getPacksForBulkUpdateAPI,
  allocationPoCreationBeforeFinalize,
  saveStoreData,
} from "../../routes/api";

import {
  GET_STORE_DATA,
  GET_STORE_DETAILS,
  GET_STORE_PRODUCT_DETAILS,
  GET_PRODUCT_DATA,
  GET_PRODUCT_DETAILS,
  EDIT_ALLOCATED_UNITS,
  FINALIZE_ALLOCATION,
  CONFIRM_DATA_ADA,
  DOWNLOAD_EXCEL,
  DOWNLOAD_XML,
  DOWNLOAD_CSV,
  DOWNLOAD_CSV_ERROR,
  DOWNLOAD_CSV_SUCCESS,
  getStoreDataSuccess,
  getStoreDataError,
  getStoreDetailsSuccess,
  getStoreDetailsError,
  getProductDataSuccess,
  getProductDataError,
  getProductDetailsSuccess,
  getProductDetailsError,
  getStoreProductDetailsSuccess,
  getStoreProductDetailsError,
  editAllocatedUnitSuccess,
  editAllocatedUnitError,
  downloadExcelSuccess,
  downloadExcelError,
  downloadXmlSuccess,
  downloadXmlError,
  finalizeAllocationSuccess,
  finalizeAllocationError,
  UPDATE_RESERVE_QTY,
  updateReserveQuantitySuccess,
  updateReserveQuantityError,
  GET_PAST_ALLOCATION_PO,
  GET_PACK_DETAILS,
  getPackDetailsSuccess,
  getPackDetailsError,
  uploadFileSuccess,
  uploadFileError,
  UPLOAD_FILE,
  UPDATE_FINALIZE_INFO,
  updateFinalizeInfoSuccess,
  updateFinalizeInfoError,
  UPDATE_PACKS_EACHES_FOR_STORES,
  updatePacksEachesForMultipleStoresSuccess,
  updatePacksEachesForMultipleStoresError,
  DISABLE_EDIT,
  disableEditSuccess,
  disableEditError,
  confirmDataAdaSuccess,
  confirmDataAdaFailed,
  GET_PACKS_FOR_BULK_UPDATE,
  getPacksForBulkEditSuccess,
  getPacksForBulkEditError,
  DOWNLOAD_CSV_BEFORE_FINALIZE,
  downloadCsvBeforeFinalizeError,
  downloadCsvBeforeFinalizeSuccess,
  saveStoreDataError,
  SAVE_STORE_DATA,
  saveStoreDataSuccess,
} from "./FinalizeAction";
import moment from "moment";
import { getLevelFilterLabelsForCurrentClient } from "../../utils/commonUtilities";
import { cloneDeep, isEmpty } from "lodash";

const getLevelFilterObject = (po) => {
  let filters = getLevelFilterLabelsForCurrentClient();
  let filterObj = {};
  filters.forEach((ele) => {
    filterObj[ele.label] = po[ele.key];
  });
  return filterObj;
};

function* getStoreDataWorker(action) {
  try {
    let response = yield call(getStoreViewData, action.payload);
    let data = response.data.storeViewData;
    let storeAllocationData = response.data.storeAllocationData;
    let storeObj = {};
    data.forEach((item) => {
      if (storeObj[item.store_code]) {
        storeObj[item.store_code].dc_codes.push(item.dc_codes);
        storeObj[item.store_code].dc_names.push(item.dc_name);
        storeObj[item.store_code].dates.push(item.shipping_date);
        storeObj[item.store_code][`DC - ${item.dc_name}`] = item.shipping_date;
      } else {
        storeObj[item.store_code] = {
          ...item,
          dc_codes: [item.dc_codes],
          dates: [item.shipping_date],
          dc_names: [item.dc_name],
          [`DC - ${item.dc_name}`]: item.shipping_date,
        };
      }
    });

    if (response.data.status) {
      yield put(
        getStoreDataSuccess({
          data: Object.values(storeObj),
          storeAllocationData: [storeAllocationData],
          dc: response.data.dc_list,
          allocationStatus: response.data.allocationStatus
        })
      );
    } else {
      yield put(getStoreDataError({ error: "Something Went Wrong" }));
    }
  } catch (error) {
    yield put(getStoreDataError(error));
  }
}

function* getPacksForBulkUpdateWorker(action) {
  const response = yield call(getPacksForBulkUpdateAPI, { ...action.payload });
  try {
    if (response.data.status) {
      yield put(getPacksForBulkEditSuccess({ data: response?.data }));
    } else {
      yield put(
        getPacksForBulkEditError({
          error: response.message || "Something Went Wrong!!",
        })
      );
    }
  } catch (error) {
    yield put(getPacksForBulkEditError("Something Went Wrong!!"));
  }
}

function* getStoreDetailsWorker(action) {
  try {
    const response = yield call(getProductView, action.payload);

    if (response.data.status) {
      yield put(
        getStoreDetailsSuccess({ data: response.data.productViewData })
      );
    } else {
      yield put(getStoreDetailsError({ error: "Something Went Wrong" }));
    }
  } catch (error) {
    yield put(getStoreDetailsError(error));
  }
}

function* getStoreProductDetailsWorker(action) {
  try {
    const response = yield call(getProductStoreView, action.payload);

    if (response.status) {
      yield put(
        getStoreProductDetailsSuccess({
          data: {
            ...response.data.productStoreViewData,
            dc_map: response?.data?.dc_map,
          },
        })
      );
    } else {
      yield put(getStoreProductDetailsError({ error: "Something Went Wrong" }));
    }
  } catch (error) {
    yield put(getStoreProductDetailsError(error));
  }
}

function* getProductDataWorker(action) {
  try {
    const response = yield call(getProductView, action.payload);
    let productAllocationData = response.data.productAllocationData;

    if (response.status) {
      let productViewData = cloneDeep(response?.data?.productViewData);
      productViewData.data?.forEach((item) => {
        item.store_group = item?.store_group?.join(", ") || null;
      });
      yield put(
        getProductDataSuccess({
          data: productViewData,
          productAllocationData: [productAllocationData],
          allocationStatus: response.data.allocationStatus
        })
      );
    } else {
      yield put(getProductDataError({ error: "Something Went Wrong" }));
    }
  } catch (error) {
    yield put(getProductDataError(error));
  }
}

function* getProductDetailsWorker(action) {
  try {
    const response = yield call(getProductStoreView, action.payload);
    if (response.status) {
      yield put(
        getProductDetailsSuccess({
          data: {
            ...response.data.productStoreViewData,
            dc_map: response?.data?.dc_map,
          },
        })
      );
    } else {
      yield put(getProductDetailsError({ error: "Something Went Wrong" }));
    }
  } catch (error) {
    yield put(getProductDetailsError(error));
  }
}

function* editAllocatedUnitWorker(action) {
  try {
    let response;
    if (action.payload?.isNewStoreSetup) {
      delete action.payload?.isNewStoreSetup;
      response = yield call(editNewStoreReserved, action.payload);
    } else {
      response = yield call(editAllocatedUnits, action.payload);
    }
    if (response.data.status) {
      yield put(
        editAllocatedUnitSuccess({
          data: true,
          allocationCode: response?.data?.data?.allocation_code,
        })
      );
    } else {
      yield put(editAllocatedUnitError({ error: "Something Went Wrong" }));
    }
  } catch (error) {
    yield put(editAllocatedUnitError("Something Went Wrong!!"));
  }
}

function* finalizeAllocation(action) {
  const { comments, order_type, delivery_dates } = action.payload

  let req = [
    {
      allocation_code: action.payload.allocation_code,
      data: [],
      retail_pro_id: action.payload.retail_pro_id,
      comments, 
      order_type, 
      delivery_dates,
    },
  ];
  let data = [...action.payload.data];
  let articles = action.payload.articles;
  data.forEach((item) => {
    let obj = {
      store: item.store_code,
      dc_codes: item.dc_codes,
      shipping_dates: [],
    };
    item.dc_names.forEach((dc, index) => {
      obj.shipping_dates.push(
        item[`DC - ${dc}`] ? item[`DC - ${dc}`] : item.dates[index]
      );
    });
    req[0].data.push(obj);
  });
  if (articles?.length) req[0]["article"] = articles;

    const response = yield call(allocationPoCreation, req, action.source)
    try {
        if (response?.data?.status) {
            if(!isEmpty(response.data)) {
                const { details } = response.data;
                delete response.data.details
                yield put(finalizeAllocationSuccess({ 'data': response.data, details }))
            }
            else {
                yield put(finalizeAllocationError({ 'error': "Error in finalize data!!" }));
            }
        }
        else {
            yield put(finalizeAllocationError({ 'error': response?.data?.message || "Error in finalize!!" }));
        }
    }
    catch (error) {
      yield put(finalizeAllocationError({ 'error': "Something Went Wrong" }));
    }
}


function* confirmDataAdaWorker(action) {
  try {
    let { payload } = action;
    const res = yield call(confirmDataAda, payload);
    if (res.data.status) {
      yield put(confirmDataAdaSuccess({ data: "Success" }));
    }
  } catch (error) {
    yield put(confirmDataAdaFailed({ error: "Something went wrong" }));
  }
}

function* getPastAllocationPOWorker(action) {
  try {
    let { payload } = action
    const response = yield call(pastAllocationPoCreation, [payload])
    if (response.status) {
        const { details } = response?.data;
        delete response?.data?.details
        yield put(finalizeAllocationSuccess({ 'data': response.data, details }))
    }
    else {
        yield put(finalizeAllocationError({ 'error': "Something Went Wrong" }));
    }
  } catch (error) {
    yield put(finalizeAllocationError(error));
  }
}

function* downloadXmlworker(action) {
  let PO = [];
  let POQtys = [];
  let POItems = [];
  action.payload.PO.forEach((po) => {
    let PoObj = {
      _attributes: {
        empl_name: po.empl_name,
        po_no: po.po_no,
        sbs_no: po.sbs_no,
        store_no: po.store_no,
        po_type: po.po_type,
        status: po.status,
        active: po.active,
        shipto_store_no: po.shipto_store_no,
        billto_store_no: po.billto_store_no,
        vend_code: po.vend_code,
        created_date: moment(po.created_date)
          .utcOffset("-0400")
          .format("YYYY-MM-DDThh:mm:ss"),
        modified_date: moment(po.modified_date)
          .utcOffset("-0400")
          .format("YYYY-MM-DDThh:mm:ss"),
        shipping_date: moment(po.shipping_date)
          .utcOffset("-0400")
          .format("YYYY-MM-DDThh:mm:ss"),
        cancel_date: moment(po.cancel_date)
          .utcOffset("-0400")
          .format("YYYY-MM-DDThh:mm:ss"),
        sent_date: moment(po.sent_date)
          .utcOffset("-0400")
          .format("YYYY-MM-DDThh:mm:ss"),
        season_code: po.season_code,
      },
    };
    POItems = [];
    po.PO_Item.sort(sortPOItemByItemPos);
    po.PO_Item.forEach((poItem) => {
      POQtys = [];
      poItem.PO_Qty.forEach((qty) => {
        POQtys.push({
          PO_QTY: {
            _attributes: {
              store_no: qty.store_no,
              ord_qty: qty.ord_qty,
            },
          },
        });
      });

      POItems.push({
        _attributes: {
          item_pos: poItem.item_pos,
          item_sid: poItem.item_sid,
          price: poItem.price,
          cost: poItem.cost,
        },
        PO_QTYS: POQtys,
      });
    });
    PoObj.PO_ITEMS = [{ PO_ITEM: POItems }];
    PO.push(PoObj);
  });
  try {
    const response = {
      status: true,
      data: {
        _declaration: {
          _attributes: {
            version: "1.0",
            encoding: "UTF-8",
          },
        },
        DOCUMENT: {
          POS: [
            {
              PO: PO,
            },
          ],
        },
      },
    };
    if (response.status) {
      yield put(downloadXmlSuccess({ data: response.data }));
    } else {
      yield put(downloadXmlError({ error: "Something Went Wrong" }));
    }
  } catch (error) {
    yield put(downloadXmlError(error));
  }
}

function* downloadExcelWorker(action) {
  let data = [];
  action.payload.PO.forEach((po) => {
    let po_obj = {
      Empl_Name: po.empl_name,
      "PO NO": po.po_no,
      "SBS NO": po.sbs_no,
      "Store No": po.store_no,
      "Store Name": po.store_name,
      "DC Name": po.DC_Name,
      "PO Type": po.po_type,
      "Ship to Store NO": po.shipto_store_no,
      "Bill to Store NO": po.billto_store_no,
      "Vend Code": po.vend_code,
      "Created Date": moment(po.created_date)
        .utcOffset("-0400")
        .format("YYYY-MM-DDThh:mm:ss"),
      "Modified Date": moment(po.modified_date)
        .utcOffset("-0400")
        .format("YYYY-MM-DDThh:mm:ss"),
      "Shipping Date": moment(po.shipping_date)
        .utcOffset("-0400")
        .format("YYYY-MM-DDThh:mm:ss"),
      "Cancel Date": moment(po.cancel_date)
        .utcOffset("-0400")
        .format("YYYY-MM-DDThh:mm:ss"),
      "Sent Date": moment(po.sent_date)
        .utcOffset("-0400")
        .format("YYYY-MM-DDThh:mm:ss"),
      "Season Code": po.season_code,
    };
    po.PO_Item.forEach((po) => {
      let final_obj = {
        ...po_obj,
        "Item sid": po.item_sid,
        "Item POS": po.item_pos,
        ...getLevelFilterObject(po),
        Article: po.article,
        Style: po.style_name,
        "Color Desc": po.color_desc,
        Actual_ROS: po.actual_ros,
        Target_WOS: po.target_wos,
        "On Hand": po.oh,
        "On Order": po.oo,
        "In Transit": po.it,
        Min_Constraint: po.min_constraint,
        Size: po.size,
        Price: po.price,
        Cost: po.cost,
        "Order Quantity": po.PO_Qty[0]?.ord_qty,
      };
      data.push(final_obj);
    });
  });
  try {
    const response = {
      status: true,
      data,
    };
    if (response.status) {
      yield put(downloadExcelSuccess({ data: response.data }));
    } else {
      yield put(downloadExcelError({ error: "Something Went Wrong" }));
    }
  } catch (error) {
    yield put(downloadExcelError(error));
  }
}

function* downloadCsvWorker(action) {
  let data = cloneDeep(
    action?.payload?.Output ? action?.payload?.Output : action?.payload?.PO
  );
  let objKeys = Object.keys(data[0]);
  objKeys = objKeys.map(key => {
    switch(key) {
      case "expediteFlag":
        return "comments";
      case "instore_date":
        return "delivery_date";
      default:
        return key;
    }
  });
  let nonReqKeys = [
    "allocation_code_new",
    "created_at",
    "po_number",
    "asn",
    "reallocation",
    "store_sap",
    "dc_code_sap",
  ];

  try {
    let headers = [];
    objKeys.forEach((key) => {
      !nonReqKeys.includes(key) &&
        headers.push({
          label: key
            .split("_")
            .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
            .join(" "),
          key,
        });
    });
    yield put({ type: DOWNLOAD_CSV_SUCCESS, data: headers });
  } catch (error) {
    yield put({ type: DOWNLOAD_CSV_ERROR, error: "Something Went Wrong!!" });
  }
}

function* downloadCsvBeforeFinalizeWorker(action) {
  let nonReqKeys = ["expediteFlag"]

  try {
    const response = yield call(allocationPoCreationBeforeFinalize, action?.payload);
    let headers = [];
    let objKeys = Object.keys(response?.data?.data[0]);
    objKeys.forEach((key) => {
        !nonReqKeys.includes(key) && headers.push({
          label: key
            .split("_")
            .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
            .join(" "),
          key,
        });
    });
    if (response?.data?.status) {
      yield put(downloadCsvBeforeFinalizeSuccess({ data: response?.data?.data, headers:headers }));
    } else {
      yield put(downloadCsvBeforeFinalizeError({ error: "Something Went Wrong" }));
    }
  } catch (error) {
    yield put(downloadCsvBeforeFinalizeError(error));
  }

}

function* getPackDetailsWorker(action) {
  try {
    let { payload } = action;
    let response;
    if (payload?.isNewStoreSetup) {
      if (payload?.isRelease) {
        response = { data: { ...payload?.sizeData, status: true } };
      } else {
        delete payload.isNewStoreSetup;
        response = yield call(getNewStoreEditReservedDetails, payload);
      }
    } else {
      response = yield call(getPackDetailsApi, payload);
    }
    if (response.data.status) {
      let data1 = [
        // {
        //     "1X": 2,
        //     "2X": 2,
        //     "3X": 2,
        //     "units_in_pack": 6,
        //     "allocated_packs": 1,
        //     "allocated_units": 6,
        //     "pack_id": "packId1",
        //     "packs_available": 6,
        //     "units_available": 36,
        // },
        // {
        //     "1X": 2,
        //     "2X": 2,
        //     "3X": 2,
        //     "units_in_pack": 6,
        //     "allocated_packs": 2,
        //     "allocated_units": 12,
        //     "pack_id": "packId2",
        //     "packs_available": 12,
        //     "units_available": 72,
        // },
        ...response?.data?.data,
      ];

      let data = data1.map((val) => {
        return {
          ...val,
          max_pack:
            Number(val.allocated_packs) + Number(val.packs_available) || 0,
          max_pack_available:
            Number(val.allocated_units) + Number(val.units_available) || 0,
          eaches:val?.pack_id  
        };
      });

      let columns = response?.data?.pack_sizes;

      let eaches_data = data.filter(
        (val) => val.pack_id === "eaches" || val.pack_id === "eaches available"
      );

      let non_eaches_data = data.filter(
        (val) => val.pack_id !== "eaches" && val.pack_id !== "eaches available"
      );

      let l_data = [...non_eaches_data, ...eaches_data];
      l_data.forEach((item) => (item.col_name = item.pack_id));

      let max_size_mapping = {};

      columns.forEach((val) => {
        max_size_mapping[val] = eaches_data.reduce(function (sum, current) {
          return sum + current?.[val];
        }, 0);
      });

      yield put(
        getPackDetailsSuccess({
          data: l_data,
          columns: columns,
          max_size_mapping: max_size_mapping,
        })
      );
    } else {
      yield put(getPackDetailsError({ error: "Something Went Wrong" }));
    }
  } catch (error) {
    yield put(getPackDetailsError(error));
  }
}

function* updateReserveQtyWorker(action) {
  const response = yield call(updateResQty, { data: action.payload });
  // let response = POSample
  try {
    if (response.status) {
      yield put(updateReserveQuantitySuccess({ data: response.data }));
    } else {
      yield put(updateReserveQuantityError({ error: "Something Went Wrong" }));
    }
  } catch (error) {
    yield put(updateReserveQuantityError(error));
  }
}

function* updateFinalizeInfoWorker(action) {
  try {
    let { payload } = action;
    const response = yield call(updateFinalizeInfo, payload);
    if (response.status) {
      yield put(updateFinalizeInfoSuccess({ data: response.data }));
    } else {
      yield put(updateFinalizeInfoError({ error: "Something Went Wrong" }));
    }
  } catch (error) {
    yield put(updateFinalizeInfoError(error));
  }
}

function* uploadFileWorker(action) {
  const response = yield call(uploadFile, { data: action.payload });
  // let response = POSample
  try {
    if (response.status) {
      yield put(uploadFileSuccess({ data: response.data }));
    } else {
      yield put(uploadFileError({ error: "Something Went Wrong" }));
    }
  } catch (error) {
    yield put(uploadFileError(error));
  }
}

function* updatePacksEachesForStoresWorker(action) {
  const response = yield call(updatePacksEachesForStoresAPI, {
    ...action.payload,
  });
  try {
    if (response.status) {
      yield put(
        updatePacksEachesForMultipleStoresSuccess({
          allocationCode: response?.data?.data?.allocation_code,
          data: true,
        })
      );
    } else {
      yield put(
        updatePacksEachesForMultipleStoresError({
          error: "Something Went Wrong" || "Something Went Wrong!!",
        })
      );
    }
  } catch (error) {
    yield put(updatePacksEachesForMultipleStoresError(error));
  }
}

function* disableEditWorker(action) {
  try {
    let { payload } = action;
    const response = yield call(disableEditApi, payload);
    if (response.status) {
      yield put(disableEditSuccess({ data: response.data }));
    } else {
      yield put(disableEditError({ error: response.message }));
    }
  } catch (error) {
    yield put(disableEditError(error));
  }
}

function* saveStoreDataWorker(action) {
    try {
        let { payload } = action;
        const response = yield call(saveStoreData, payload);
        if (response.status) {
            yield put(saveStoreDataSuccess({ data: response.data }));
        }
    } catch (error) {
        yield put(saveStoreDataError({ error: "Something Went Wrong" }));
    }
}

function* getStoreDataWatcher() {
  yield takeLatest(GET_STORE_DATA, getStoreDataWorker);
}

function* getStoreDetailsWatcher() {
  yield takeLatest(GET_STORE_DETAILS, getStoreDetailsWorker);
}

function* getStoreProductDetailsWatcher() {
  yield takeLatest(GET_STORE_PRODUCT_DETAILS, getStoreProductDetailsWorker);
}

function* getProductDataWatcher() {
  yield takeLatest(GET_PRODUCT_DATA, getProductDataWorker);
}

function* getProductDetailsWatcher() {
  yield takeLatest(GET_PRODUCT_DETAILS, getProductDetailsWorker);
}

function* editAllocatedUnitsWatcher() {
  yield takeLatest(EDIT_ALLOCATED_UNITS, editAllocatedUnitWorker);
}

function* finalizeAllocationwatcher() {
  yield takeLatest(FINALIZE_ALLOCATION, finalizeAllocation);
}

function* confirmDataAdaWatcher() {
  yield takeLatest(CONFIRM_DATA_ADA, confirmDataAdaWorker);
}

function* downloadXmlWatcher() {
  yield takeLatest(DOWNLOAD_XML, downloadXmlworker);
}

function* downloadExcelWatcher() {
  yield takeLatest(DOWNLOAD_EXCEL, downloadExcelWorker);
}

function* updateReserveQtyWatcher() {
  yield takeLatest(UPDATE_RESERVE_QTY, updateReserveQtyWorker);
}

function* updateFinalizeInfoWatcher() {
  yield takeLatest(UPDATE_FINALIZE_INFO, updateFinalizeInfoWorker);
}

function* getPastAllocationPOWatcher() {
  yield takeLatest(GET_PAST_ALLOCATION_PO, getPastAllocationPOWorker);
}

function* downloadCsvWatcher() {
  yield takeLatest(DOWNLOAD_CSV, downloadCsvWorker);
}

function* downloadCsvBeforeFinalizeWatcher() {
  yield takeLatest(DOWNLOAD_CSV_BEFORE_FINALIZE, downloadCsvBeforeFinalizeWorker)
}

function* getPackDetailsWatcher() {
  yield takeLatest(GET_PACK_DETAILS, getPackDetailsWorker);
}

function* updatePacksEachesForStoresWatcher() {
  yield takeLatest(
    UPDATE_PACKS_EACHES_FOR_STORES,
    updatePacksEachesForStoresWorker
  );
}

function* uploadFileWatcher() {
  yield takeLatest(UPLOAD_FILE, uploadFileWorker);
}

function* getPacksForBulkUpdateWatcher() {
  yield takeLatest(GET_PACKS_FOR_BULK_UPDATE, getPacksForBulkUpdateWorker);
}

function* disableEditWatcher() {
  yield takeLatest(DISABLE_EDIT, disableEditWorker);
}

function* saveStoreDataWatcher() {
    yield takeLatest(SAVE_STORE_DATA, saveStoreDataWorker);
}

export function* finalizeSaga() {
  yield all([
    getStoreDataWatcher(),
    getStoreDetailsWatcher(),
    getProductDataWatcher(),
    getProductDetailsWatcher(),
    getStoreProductDetailsWatcher(),
    editAllocatedUnitsWatcher(),
    finalizeAllocationwatcher(),
    confirmDataAdaWatcher(),
    downloadXmlWatcher(),
    downloadExcelWatcher(),
    downloadCsvWatcher(),
    downloadCsvBeforeFinalizeWatcher(),
    getPackDetailsWatcher(),
    updatePacksEachesForStoresWatcher(),
    updateReserveQtyWatcher(),
    getPastAllocationPOWatcher(),
    uploadFileWatcher(),
    updateFinalizeInfoWatcher(),
    getPacksForBulkUpdateWatcher(),
    disableEditWatcher(),
    saveStoreDataWatcher(),
  ]);
}

function sortPOItemByItemPos(a, b) {
  if (Number(a.item_pos) < Number(b.item_pos)) {
    return -1;
  }
  if (Number(a.item_pos) > Number(b.item_pos)) {
    return 1;
  }
  return 0;
}
