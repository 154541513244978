export const EXCEL_COLUMNS_FOR_FORMATTING = {
  lw_revenue: {
    value: 17,
    isFractional: true,
    isCurruncy: true,
  },
  lw_margin: {
    value: 18,
    isFractional: true,
    isCurruncy: true,
  },
  price: {
    value: 20,
    isFractional: true,
    isCurruncy: true,
  },
  lw_qty: 16,
  bulk_remaining: 21,
  oh: 22,
  oo: 23,
  it: 24,
  wos: {
    value: 25,
    isFractional: true,
  },
  stock_out: 27,
  shortfall: 28,
  normal: 29,
  excess: 30,
};

export const LOST_SALES_EXCEL_COLUMNS_FORMATTING = {
  lost_sales: {
    value: 11,
    isFractional: true,
    isCurruncy: true,
  },
};

export const ALLOCATION_DEEP_DIVE_EXCEL_COLUMNS_FORMATTING = {
  sku_depth_store: {
    value: 21,
    isFractional: true,
    isCurruncy: false,
  },
  grade_revenue: {
    value: 22,
    isFractional: true,
    isCurruncy: true,
  },
  revenue: {
    value: 23,
    isFractional: true,
    isCurruncy: true,
  },
  grade_margin: {
    value: 24,
    isFractional: true,
    isCurruncy: true,
  },
  margin: {
    value: 25,
    isFractional: true,
    isCurruncy: true,
  },
};

export const DAILY_SUMMARY_EXCEL_COLUMNS_FORMATTING = {
  units_allocated: 10,
  dc_available: 11,
  // reserve_quantity: 12
};

export const EXCESS_INV_COLUMNS_FORMATTING = {
  inv: 10,
  ros: {
    value: 11,
    isFractional: true,
    isCurruncy: false,
  },
  unit_sold: 12,
  wos: {
    value: 13,
    isFractional: true,
    isCurruncy: false,
  },
  target_wos: {
    value: 14,
    isFractional: true,
    isCurruncy: false,
  },
  excess_inv: 15,
  excess_inv_cost: {
    value: 16,
    isFractional: true,
    isCurruncy: true,
  },
};

export const DEFAULT_CONSTRAINTS_COLUMNS_FORMATTING = {
  min_stock: 9,
  max_stock: 10,
  wos: 11,
}
